import Tag from 'src/models/tag';

export const Tags: Tag[] = [
  {
    id: 65640,
    code: 1003,
    standard: 0,
    name: 'cc personale',
    status: 1,
    userID: 0,
    environmentID: 6,
    color: '51F5F5',
    hasdate: 0,
    metatag: 'pagamento_utente',
    documents: 0,
    tagNotes: 'Questo tag indica tutte le spese eseguite con pagamenti personali',
  },
  {
    id: 65641,
    code: 1004,
    standard: 0,
    name: 'Contanti',
    status: 1,
    userID: 0,
    environmentID: 6,
    color: '2CA0FB',
    hasdate: 0,
    metatag: '',
    documents: 0,
    tagNotes: 'Questa tag indica i pagamenti eseguiti con contanti',
  },
  {
    id: 94127,
    code: 1048,
    standard: 0,
    name: 'cc aziendale',
    status: 1,
    userID: 0,
    environmentID: 6,
    color: 'FCD804',
    hasdate: 0,
    metatag: '',
    documents: 0,
    tagNotes: '',
  },
  {
    id: 96932,
    code: 1077,
    standard: 0,
    name: 'FE',
    status: 1,
    userID: 0,
    environmentID: 6,
    color: 'F6727A',
    hasdate: 0,
    metatag: '',
    documents: 0,
    tagNotes: '',
  },
  {
    id: 131722,
    code: 1126,
    standard: 0,
    name: 'Data di scadenza',
    status: 1,
    userID: 0,
    environmentID: 6,
    color: '2CA0FB',
    hasdate: 1,
    metatag: '',
    documents: 1,
    tagNotes: '',
  },
];
