import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { AuthState } from 'src/types/auth-state';
import {
  login as loginApi,
  register as registerApi,
  resendVerifyEmail as resendVerifyEmailApi,
  checkConfirmRegister as checkConfirmRegisterApi,
  confirmRegister as confirmRegisterApi,
  forgotPassword as forgotPasswordApi,
  completeInvitation as completeInvitationApi,
} from 'src/api';
import { ActionMapType, AuthUser } from 'src/types/types';
import { getSession, setSession } from 'src/utils/jwt-utils';
import { useGetMe } from 'src/hooks/use-api';
import { AuthContext } from './auth-context';

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  REGISTER = 'REGISTER',
  RESEND_VERIFY_EMAIL = 'RESEND_VERIFY_EMAIL',
  COMPLETE_REGISTER = 'COMPLETE_REGISTER',
  CHECK_CONFIRM_REGISTER = 'CHECK_CONFIRM_REGISTER',
  CONFIRM_REGISTER = 'CONFIRM_REGISTER',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
}

type Payload = {
  [Types.INITIAL]: {
    token: string;
  };
  [Types.LOGIN]: {
    user: AuthUser;
  };
  [Types.LOGOUT]: undefined;
  [Types.REGISTER]: undefined;
  [Types.RESEND_VERIFY_EMAIL]: undefined;
  [Types.COMPLETE_REGISTER]: undefined;
  [Types.CHECK_CONFIRM_REGISTER]: undefined;
  [Types.CONFIRM_REGISTER]: undefined;
  [Types.FORGOT_PASSWORD]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

type AuthStateType = {
  user: AuthUser | null;
  loading: boolean;
  token: string;
};

const initialState: AuthStateType = {
  user: null,
  loading: true,
  token: '',
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  switch (action.type) {
    case Types.INITIAL:
      return {
        loading: false,
        token: action.payload.token,
        user: null,
      };
    case Types.LOGIN:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.user?.accessToken ?? '',
      };
    case Types.LOGOUT:
      return {
        ...state,
        user: null,
        token: '',
      };
    case Types.REGISTER:
    case Types.RESEND_VERIFY_EMAIL:
    case Types.CHECK_CONFIRM_REGISTER:
    case Types.CONFIRM_REGISTER:
    case Types.COMPLETE_REGISTER:
    case Types.FORGOT_PASSWORD:
      return {
        ...state,
      };
    default:
      return state;
  }
};

// ----------------------------------------------------------------------

type AuthProviderProps = PropsWithChildren;

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { data: user } = useGetMe({
    enabled: !!state.token,
  });

  const initialize = useCallback(async () => {
    const accessToken = getSession();

    if (accessToken) {
      setSession(accessToken);

      dispatch({
        type: Types.INITIAL,
        payload: {
          token: accessToken,
        },
      });
    } else {
      dispatch({
        type: Types.INITIAL,
        payload: {
          token: '',
        },
      });
    }
  }, []);

  useEffect(() => {
    void initialize();
  }, [initialize]);

  useEffect(() => {
    if (user) {
      dispatch({
        type: Types.LOGIN,
        payload: {
          user: {
            ...user,
            displayName: `${user?.first_name} ${user?.last_name}`,
            accessToken: state.token,
          },
        },
      });
    }
  }, [state.token, user]);

  const login = useCallback(
    (email: string, password: string) =>
      new Promise<void>((resolve, reject) => {
        loginApi(email, password)
          .then((_user: AuthUser) => {
            const { accessToken } = _user ?? {
              accessToken: null,
            };

            setSession(accessToken);

            dispatch({
              type: Types.LOGIN,
              payload: {
                user: _user,
              },
            });

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      }),
    []
  );

  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  const register = useCallback(
    async (
      email: string,
      password: string,
      firstName: string,
      lastName: string,
      frequency: string,
      plan_type: string,
      recaptcha: string
    ) => {
      registerApi(email, password, firstName, lastName, frequency, plan_type, recaptcha).then(
        (_user: AuthUser) => {
          dispatch({
            type: Types.REGISTER,
          });
        }
      );
    },
    []
  );

  const resendVerifyEmail = useCallback(async (email: string) => {
    void resendVerifyEmailApi(email);
  }, []);

  const completeInvitation = useCallback(
    (code: string, password: string, firstName: string, lastName: string) =>
      new Promise<void>((resolve, reject) => {
        completeInvitationApi(code, password, firstName, lastName).then((result) => {
          dispatch({
            type: Types.COMPLETE_REGISTER,
          });

          if (result) {
            resolve();
          } else {
            reject();
          }
        });
      }),
    []
  );

  const confirmRegister = useCallback(
    (code: string) =>
      new Promise<void>((resolve, reject) => {
        confirmRegisterApi(code).then((result) => {
          dispatch({
            type: Types.CONFIRM_REGISTER,
          });

          if (result) {
            resolve();
          } else {
            reject();
          }
        });
      }),
    []
  );

  const checkConfirmRegister = useCallback(
    (email: string) =>
      new Promise<void>((resolve, reject) => {
        checkConfirmRegisterApi(email).then((result) => {
          dispatch({
            type: Types.CHECK_CONFIRM_REGISTER,
          });

          if (result) {
            resolve();
          } else {
            reject();
          }
        });
      }),
    []
  );

  const forgotPassword = useCallback(
    (email: string) =>
      new Promise<void>((resolve, reject) => {
        forgotPasswordApi(email)
          .then((result) => {
            dispatch({
              type: Types.FORGOT_PASSWORD,
            });

            if (result) {
              resolve();
            } else {
              reject();
            }
          })
          .catch((error) => {
            reject(error);
          });
      }),
    []
  );

  const resolvedAuthState: AuthState = useMemo(
    () => ({
      isLoading: state.loading,
      isAuthenticated: state.token !== '',
      user: state.user,
      login,
      logout,
      register,
      resendVerifyEmail,
      completeInvitation,
      confirmRegister,
      checkConfirmRegister,
      forgotPassword,
    }),
    [
      state,
      login,
      logout,
      register,
      resendVerifyEmail,
      completeInvitation,
      confirmRegister,
      checkConfirmRegister,
      forgotPassword,
    ]
  );

  return <AuthContext.Provider value={resolvedAuthState}>{children}</AuthContext.Provider>;
};
