import accountPopover from './it/account-popover.json';
import activation from './it/activation.json';
import apiError from './it/api-error.json';
import categories from './it/categories.json';
import clients from './it/clients.json';
import confirmInvitation from './it/confirm-invitation.json';
import costCenters from './it/cost-centers.json';
import dashboard from './it/dashboard.json';
import downloadApp from './it/download-app.json';
import environments from './it/environments.json';
import forgotPassword from './it/forgot-password.json';
import general from './it/general.json';
import jobOrders from './it/job-orders.json';
import login from './it/login.json';
import navmenu from './it/navmenu.json';
import profile from './it/profile.json';
import register from './it/register.json';
import reports from './it/reports.json';
import resetPassword from './it/reset-password.json';
import tags from './it/tags.json';
import upload from './it/upload.json';
import userPlan from './it/user-plan.json';
import users from './it/users.json';
import verify from './it/verify.json';

export default {
  'account-popover': accountPopover,
  activation,
  'api-error': apiError,
  categories,
  clients,
  'confirm-invitation': confirmInvitation,
  'cost-centers': costCenters,
  dashboard,
  'download-app': downloadApp,
  environments,
  'forgot-password': forgotPassword,
  general,
  'job-orders': jobOrders,
  login,
  navmenu,
  profile,
  register,
  reports,
  'reset-password': resetPassword,
  tags,
  upload,
  'user-plan': userPlan,
  users,
  verify,
};
