import { endpoints } from 'src/config/endpoints';
import Report from 'src/models/report';
import ReportFee from 'src/models/report-fee';
import apiBase from './api-base';

export const getReportsCount = (
  environmentId: number,
  from: string | undefined | null,
  to: string | undefined | null,
  name: string | undefined,
  status: number | undefined,
  userId: string | number | undefined
): Promise<{ total: number; sumValue: number }> =>
  new Promise((resolve, reject) => {
    apiBase
      .get(endpoints.reports.count(environmentId), { params: { from, to, name, status, userId } })
      .then((response) => {
        resolve({
          total: parseInt(response.data.Data.total, 10) || 0,
          sumValue: response.data.Data.sumValue,
        });
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getAllReports = (
  environmentId: number,
  limit?: number,
  page?: number,
  from?: string | undefined | null,
  to?: string | undefined | null,
  name?: string,
  status?: number,
  orderBy?: string,
  orderType?: string,
  userId?: string | number
): Promise<Report[]> =>
  new Promise((resolve, reject) => {
    apiBase
      .get(endpoints.reports.getAll(environmentId), {
        params: { limit, page, from, to, name, status, orderBy, orderType, userId },
      })
      .then((response) => {
        resolve(response.data.Data as Report[]);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getReport = (reportId: number | string): Promise<Report> =>
  new Promise((resolve, reject) => {
    apiBase
      .get(endpoints.reports.get(reportId))
      .then((response) => {
        resolve(response.data.Data as Report);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getReportFees = (reportId: number): Promise<ReportFee[]> =>
  new Promise((resolve, reject) => {
    apiBase
      .get(endpoints.reports.getFees(reportId))
      .then((response) => {
        resolve(response.data.Data as ReportFee[]);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getUserReports = (
  userId: string | number,
  environmentId: string | number
): Promise<Report[]> =>
  new Promise((resolve, reject) => {
    apiBase.get(endpoints.reports.getUserReports(userId, environmentId)).then((response) => {
      resolve(response.data.Data as Report[]);
    });
  });

export const acceptReport = (reportId: number | string): Promise<Report> =>
  new Promise((resolve, reject) => {
    apiBase
      .put(endpoints.reports.accept(reportId))
      .then((response) => {
        resolve(response.data.Data as Report);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const deleteReport = (reportId: number | string): Promise<Report> =>
  new Promise((resolve, reject) => {
    apiBase
      .delete(endpoints.reports.delete(reportId))
      .then((response) => {
        resolve(response.data.Data as Report);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const rejectReport = (reportId: number | string, notes: string): Promise<Report> =>
  new Promise((resolve, reject) => {
    apiBase
      .put(endpoints.reports.reject(reportId), { notes })
      .then((response) => {
        resolve(response.data.Data as Report);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const updateReportFee = (reportId: number | string, fee: ReportFee): Promise<ReportFee> =>
  new Promise((resolve, reject) => {
    apiBase
      .put(endpoints.reports.updateFee(reportId, fee.idfee), fee)
      .then((response) => {
        resolve(response.data.Data as ReportFee);
      })
      .catch((error) => {
        reject(error);
      });
  });
export const getReportPdf = (reportId: number | string): Promise<string> =>
  new Promise((resolve, reject) => {
    apiBase
      .get(endpoints.reports.pdf(reportId))
      .then((response) => {
        resolve(response.data.Data as string);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const updateDocumentNumberReportFee = (
  reportId: number | string,
  fee: ReportFee
): Promise<string> =>
  new Promise((resolve, reject) => {
    apiBase
      .put(endpoints.reports.updateFee(reportId, fee.id), fee)
      .then((response) => {
        resolve(response.data.Data as string);
      })
      .catch((error) => {
        reject(error);
      });
  });
