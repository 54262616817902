import { http, delay, HttpResponse } from 'msw';
import { API_BASE_URL } from 'src/api/api-base';
import { endpoints } from 'src/config/endpoints';
import { Reports } from 'src/fixtures/reports';
import { ReportFees } from 'src/fixtures/report-fees';

export default [
  http.get(API_BASE_URL + endpoints.reports.getAll, async () => {
    await delay('real');
    return HttpResponse.json(Reports);
  }),
  http.get(
    API_BASE_URL + endpoints.reports.getUserReports(':userId', ':environmentId'),
    async () => {
      await delay('real');
      return HttpResponse.json(Reports);
    }
  ),
  http.get(API_BASE_URL + endpoints.reports.getFees(':id'), async () => {
    await delay('real');
    return HttpResponse.json(ReportFees);
  }),
  /*
  http.put(API_BASE_URL + endpoints.reports.acceptFee(':reportId', ':feeId'), async (req) => {
    await delay('real');

    const { feeId } = req.params;
    const index = ReportFees.findIndex((reportFee) => reportFee.idfee === Number(feeId));
    ReportFees[index].status = 1;

    return HttpResponse.json(ReportFees[index]);
  }),
  http.put(API_BASE_URL + endpoints.reports.rejectFee(':reportId', ':feeId'), async (req) => {
    await delay('real');

    const { feeId } = req.params;
    const index = ReportFees.findIndex((reportFee) => reportFee.idfee === Number(feeId));
    ReportFees[index].status = 2;

    return HttpResponse.json(ReportFees[index]);
  }),
  */
];
