import SvgColor from 'src/components/svg-color';
import React, { RefAttributes } from 'react';
import Iconify from 'src/components/iconify';
import { SvgColorProps } from 'src/components/svg-color/svg-color';

export const svgIcon = (name: string, props: undefined | Omit<SvgColorProps, 'src'> & RefAttributes<HTMLSpanElement> = undefined) => (
  <SvgColor src={`/assets/icons/app/${name}.svg`} sx={{ width: 20, height: 20 }} {...props} />
);

const iconifyIcon = (name: string) => <Iconify icon={name} width="20px" height="20px" />;

export const ICONS = {
  calendar: svgIcon('calendar'),
  dashboard: svgIcon('dashboard'),
  reports: svgIcon('reports'),
  users: svgIcon('users'),
  settings: svgIcon('settings'),
  award: svgIcon('award'),
  arrow_right: iconifyIcon('solar:arrow-right-linear'),
  reports_approved: svgIcon('reports-approved'),
  reports_total: svgIcon('reports-total'),
  reports_pending: svgIcon('reports-pending'),
  reports_rejected: svgIcon('reports-rejected'),
};
