import UserModel from 'src/models/user';

export const User: UserModel = {
  id: 1,
  email: 'thomas@fees.world',
  first_name: 'Thomas',
  last_name: 'Cocchiara',
  address: 'Via Roma 1',
  avatar: 'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50',
  birthdate: '1980-01-01',
  codice_fiscale: 'CCCTMS80A01H501X',
  company: 'Fees',
  phone: '+39 123 456 7890',
  sex: 'M',
  vat_code: '12345678901',
  premiumlevel: 'FEES_PLUS_ANNUAL',
  n_licenze_superadmin_bu: 5,
  username: 'thomas',
  initial_tutorial_categorie: 1,
  initial_tutorial_utenti: 1,
  initial_tutorial_report: 0,
  setting_report_tutorial: 1,
  setting_categorie_tutorial: 1,
  setting_centri_costo_tutorial: 1,
  setting_clienti_tutorial: 1,
  setting_commesse_tutorial: 1,
  setting_tag_tutorial: 1,
  setting_utenti_tutorial: 1,
};
