import { http, delay, HttpResponse } from 'msw';
import { authUser } from 'src/fixtures/auth-user';
import { API_BASE_URL } from 'src/api/api-base';
import { endpoints } from 'src/config/endpoints';
import { User } from 'src/fixtures/user';

export default [
  http.post(API_BASE_URL + endpoints.auth.login, async () => {
    await delay('real');
    return HttpResponse.json(authUser);
  }),
  http.get(API_BASE_URL + endpoints.auth.me, async () => {
    await delay('real');
    return HttpResponse.json(User);
  }),
  http.post(API_BASE_URL + endpoints.auth.register, async () => {
    await delay('real');
    return HttpResponse.json(authUser);
  }),
  http.post(API_BASE_URL + endpoints.auth.resendVerifyEmail, async () => {
    await delay('real');
    return HttpResponse.json({});
  }),
  http.get(API_BASE_URL + endpoints.auth.confirmRegister, async () => {
    await delay('real');
    return HttpResponse.json(true);
  }),
  http.post(API_BASE_URL + endpoints.auth.forgotPassword, async () => {
    await delay('real');
    return HttpResponse.json(true);
  }),
];
