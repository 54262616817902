// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
// components
import { useAuthContext } from 'src/auth/hooks';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { useSessionContext } from 'src/contexts/session-context/hooks';

// ----------------------------------------------------------------------

export default function NavProfile() {
  const { user } = useAuthContext();
  const { environment } = useSessionContext();

  const router = useRouter();

  const goToProfile = () => {
    router.push(paths.dashboard.profile.root);
  };

  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        <Box sx={{ position: 'relative' }} mb={1}>
          {environment && (
            <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
              {environment.environmentname}
            </Typography>
          )}
        </Box>

        <Box sx={{ position: 'relative' }}>
          <Avatar
            src={user?.avatar}
            alt={user?.displayName}
            sx={{
              width: 36,
              height: 36,
              border: (theme) => `solid 2px ${theme.palette.background.default}`,
              cursor: 'pointer',
            }}
            onClick={goToProfile}
          >
            {user?.displayName.charAt(0).toUpperCase()}
          </Avatar>
        </Box>

        <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName.truncate(20)}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
            {user?.email}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
