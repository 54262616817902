import { endpoints } from 'src/config/endpoints';
import apiBase from 'src/api/api-base';
import CostCenter from 'src/models/cost-center';

export const getCostCenter = (id: number | string): Promise<CostCenter> =>
  new Promise((resolve, reject) => {
    apiBase.get(endpoints.costCenters.getCostCenter(id)).then((response) => {
      resolve(response.data.Data as CostCenter);
    });
  });

export const getCostCentersCount = (
  environmentId: number,
  name?: string,
): Promise<number> =>
  new Promise((resolve, reject) => {
    apiBase
      .get(endpoints.costCenters.count(environmentId), {
        params: { name },
      })
      .then((response) => {
        resolve(parseInt(response.data.Data, 10) || 0);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getAllCostCenters = (
  environmentId: number,
  name?: string,
  orderBy?: string,
  orderType?: string,
  limit?: number,
  page?: number,
): Promise<CostCenter[]> =>
  new Promise((resolve, reject) => {
    apiBase
      .get(endpoints.costCenters.getAll(environmentId), {
        params: { limit, page, name, orderBy, orderType },
      })
      .then((response) => {
        resolve(response.data.Data as CostCenter[]);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const deleteCostCenter = (id: number): Promise<CostCenter[]> =>
  new Promise((resolve, reject) => {
    apiBase.delete(endpoints.costCenters.delete(id)).then((response) => {
      resolve(response.data.Data as CostCenter[]);
    });
  });

export const createCostCenter = (
  environmentId: string | number,
  costCenter: CostCenter
): Promise<CostCenter[]> =>
  new Promise((resolve, reject) => {
    apiBase
      .post(endpoints.costCenters.create(environmentId), costCenter)
      .then((response) => {
        resolve(response.data.Data as CostCenter[]);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const updateCostCenter = (costCenter: CostCenter): Promise<CostCenter[]> =>
  new Promise((resolve, reject) => {
    apiBase
      .put(endpoints.costCenters.updateCostCenter(costCenter.id), costCenter)
      .then((response) => {
        resolve(response.data.Data as CostCenter[]);
      });
  });
