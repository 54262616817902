import { Suspense } from 'react';
import './polyfills';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import './locales/i18n';
import { HelmetProvider } from 'react-helmet-async';
import App from './app';
import 'simplebar-react/dist/simplebar.min.css';

import { worker } from './mocked-server/browser';

if (
  // process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_MOCK_API === '1') {
  void worker.start();
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <App />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
