import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
// utils
import { localStorageGetItem } from 'src/utils/storage-available';
// components
import { useSettingsContext } from 'src/components/settings';
//
import { allLangs, defaultLang, Lang } from './config-lang';

// ----------------------------------------------------------------------

export type TranslatorFunction = (key: string, ...args: (string | number)[]) => string;

export default function useLocales(): {
  allLangs: Lang[];
  t: TranslatorFunction;
  currentLang: Lang;
  onChangeLang: (newlang: string) => void;
} {
  const { i18n, t } = useTranslation();

  const settings = useSettingsContext();

  const langStorage = localStorageGetItem('i18nextLng');

  const currentLang = allLangs.find((lang) => lang.value === langStorage) || defaultLang;

  const onChangeLang = useCallback(
    (newlang: string) => {
      void i18n.changeLanguage(newlang);
      settings.onChangeDirectionByLang(newlang);
    },
    [i18n, settings]
  );

  return {
    allLangs,
    t: (key: string, ...args: (string | number)[]) => t(key).format(...args),
    currentLang,
    onChangeLang,
  };
}
