import accountPopover from './en/account-popover.json';
import activation from './en/activation.json';
import apiError from './en/api-error.json';
import categories from './en/categories.json';
import clients from './en/clients.json';
import confirmInvitation from './en/confirm-invitation.json';
import costCenters from './en/cost-centers.json';
import dashboard from './en/dashboard.json';
import downloadApp from './en/download-app.json';
import environments from './en/environments.json';
import forgotPassword from './en/forgot-password.json';
import general from './en/general.json';
import jobOrders from './en/job-orders.json';
import login from './en/login.json';
import navmenu from './en/navmenu.json';
import profile from './en/profile.json';
import register from './en/register.json';
import reports from './en/reports.json';
import resetPassword from './en/reset-password.json';
import tags from './en/tags.json';
import upload from './en/upload.json';
import userPlan from './en/user-plan.json';
import users from './en/users.json';
import verify from './en/verify.json';

export default {
  'account-popover': accountPopover,
  activation,
  'api-error': apiError,
  categories,
  clients,
  'confirm-invitation': confirmInvitation,
  'cost-centers': costCenters,
  dashboard,
  'download-app': downloadApp,
  environments,
  'forgot-password': forgotPassword,
  general,
  'job-orders': jobOrders,
  login,
  navmenu,
  profile,
  register,
  reports,
  'reset-password': resetPassword,
  tags,
  upload,
  'user-plan': userPlan,
  users,
  verify,
};
