import apiBase from 'src/api/api-base';
import { endpoints } from 'src/config/endpoints';
import Tag from 'src/models/tag';

export const getTagsCount = async (
  environmentId: string | number,
  name: string | undefined,
  type: number | undefined,
  hasdate: number | undefined
): Promise<number> =>
  new Promise((resolve, reject) => {
    apiBase
      .get(endpoints.tags.count(environmentId), {
        params: { name, type, hasdate },
      })
      .then((response) => {
        resolve(parseInt(response.data.Data, 10) || 0);
      });
  });

export const getAllTags = async (
  environmentId: string | number,
  name: string | undefined,
  orderBy: string | undefined,
  orderType: string | undefined,
  limit: number | undefined,
  page: number | undefined,
  type: number | undefined,
  hasdate: number | undefined
): Promise<Tag[]> =>
  new Promise((resolve, reject) => {
    apiBase
      .get(endpoints.tags.getAll(environmentId), {
        params: { name, orderBy, orderType, limit, page, type, hasdate },
      })
      .then((response) => {
        resolve(response.data.Data as Tag[]);
      });
  });

export const getTag = async (id: number | string): Promise<Tag> =>
  new Promise((resolve, reject) => {
    apiBase.get(endpoints.tags.getTag(id)).then((response) => {
      resolve(response.data.Data as Tag);
    });
  });
export const createTag = async (environmentId: number | string, tag: Tag): Promise<Tag> =>
  new Promise((resolve, reject) => {
    apiBase.post(endpoints.tags.create(environmentId), tag).then((response) => {
      resolve(response.data.Data as Tag);
    });
  });
export const updateTag = async (tag: Tag): Promise<Tag> =>
  new Promise((resolve, reject) => {
    apiBase.put(endpoints.tags.updateTag(tag.id), tag).then((response) => {
      resolve(response.data.Data as Tag);
    });
  });
export const deleteTag = async (id: number | string): Promise<Tag> =>
  new Promise((resolve, reject) => {
    apiBase.delete(endpoints.tags.delete(id)).then((response) => {
      resolve(response.data.Data as Tag);
    });
  });
