import React from 'react';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { AuthProvider, AuthConsumer } from 'src/auth/context';
import { LocalizationProvider } from 'src/locales';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
import Router from 'src/routes/sections';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SessionProvider } from 'src/contexts/session-context/session-provider';
import ProgressBar from 'src/components/progress-bar';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ThemeProvider from 'src/theme';
import './app.css';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  useScrollToTop();

  return (
    <QueryClientProvider client={client}>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY ?? ''}>
        <AuthProvider>
          <LocalizationProvider>
            <SettingsProvider
              defaultSettings={{
                themeMode: 'dark', // 'light' | 'dark'
                themeDirection: 'ltr', //  'rtl' | 'ltr'
                themeContrast: 'default', // 'default' | 'bold'
                themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
                themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
                themeStretch: false,
              }}
            >
              <ThemeProvider>
                <MotionLazy>
                  <SnackbarProvider>
                    <SettingsDrawer />
                    <ProgressBar />
                    <AuthConsumer>
                      <SessionProvider>
                        <Router />
                      </SessionProvider>
                    </AuthConsumer>
                  </SnackbarProvider>
                </MotionLazy>
              </ThemeProvider>
            </SettingsProvider>
          </LocalizationProvider>
        </AuthProvider>
      </GoogleReCaptchaProvider>
    </QueryClientProvider>
  );
}

export default App;
