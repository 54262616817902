import CostCenter from 'src/models/cost-center';

export const CostCenters: CostCenter[] = [
  {
    id: 1,
    name: 'Cost Center 1',
    environmentID: 1,
    code: 0,
    codice: 'A000',
    n_fees: 0,
  },
  {
    id: 2,
    name: 'Cost Center 2',
    environmentID: 1,
    code: 10,
    codice: 'B000',
    n_fees: 0,
  },
  {
    id: 3,
    name: 'Cost Center 3',
    environmentID: 1,
    code: 20,
    codice: 'C000',
    n_fees: 0,
  },
];
