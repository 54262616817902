import { http, delay, HttpResponse } from 'msw';
import { API_BASE_URL } from 'src/api/api-base';
import { endpoints } from 'src/config/endpoints';
import { JobOrders } from 'src/fixtures/job-orders';
import JobOrder from 'src/models/job-order';

export default [
  http.get(API_BASE_URL + endpoints.jobOrders.getAll, async () => {
    await delay('real');
    return HttpResponse.json(JobOrders);
  }),
  http.get(API_BASE_URL + endpoints.jobOrders.getJobOrder(':id'), async (req) => {
    await delay('real');

    const { id } = req.params;
    const index = JobOrders.findIndex((jobOrder) => jobOrder.id === Number(id));

    return HttpResponse.json(JobOrders[index]);
  }),
  http.post(API_BASE_URL + endpoints.jobOrders.create, async (req) => {
    await delay('real');

    const newJobOrder = (await req.request.json()) as JobOrder;
    newJobOrder.id = JobOrders.length + 1;
    JobOrders.push(newJobOrder);

    return HttpResponse.json(newJobOrder);
  }),
  http.put(API_BASE_URL + endpoints.jobOrders.updateJobOrder(':id'), async (req) => {
    await delay('real');

    const { id } = req.params;
    const updatedJobOrder = (await req.request.json()) as JobOrder;
    const index = JobOrders.findIndex((jobOrder) => jobOrder.id === Number(id));
    JobOrders[index] = updatedJobOrder;

    return HttpResponse.json(updatedJobOrder);
  }),
  http.delete(API_BASE_URL + endpoints.jobOrders.delete(':id'), async (req) => {
    await delay('real');

    const { id } = req.params;
    const index = JobOrders.findIndex((jobOrder) => jobOrder.id === Number(id));
    JobOrders.splice(index, 1);

    return HttpResponse.json({});
  }),
];
