import { AuthUser } from 'src/types/types';
import { User } from 'src/fixtures/user';

export const authUser: AuthUser = {
  ...User,
  group: 'bu_super_admin',
  displayName: `${User.first_name} ${User.last_name}`,
  accessToken:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyLCJleHAiOjMyNTMxMDEzNjg1fQ.yH0naTL-AaATgIMt2_L3Mp5vlhL8x8tdI8hLLXgWt88',
};
