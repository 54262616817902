import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
}

// SETUP COLORS

const GREY = {
  0: '#FFFFFF',
  100: '#FAFAFA',
  200: '#F6F6F6',
  300: '#E3E3E3',
  400: '#CDCDCD',
  500: '#9E9E9E',
  600: '#727272',
  700: '#505050',
  800: '#2C2C2C',
  900: '#1D1D1D',
};

const PRIMARY = {
  lighter: '#DCF1FE',
  light: '#8CD0FD',
  main: '#18A0FB',
  dark: '#045E9B',
  darker: '#073C60',
  contrastText: '#FFFFFF',
};

const SECONDARY = {
  lighter: '#EAE3FC',
  light: '#C1A8F6',
  main: '#733BEB',
  dark: '#4315A7',
  darker: '#2A0C69',
  contrastText: '#FFFFFF',
};

const INFO = {
  lighter: '#E1EAFF',
  light: '#B8CDFB',
  main: '#3875FF',
  dark: '#016D9C',
  darker: '#003768',
  contrastText: '#FFFFFF',
};

const SUCCESS = {
  lighter: '#E5F8ED',
  light: '#B0E8CA',
  main: '#4FCD89',
  dark: '#247B4B',
  darker: '#174F31',
  contrastText: '#ffffff',
};

const WARNING = {
  lighter: '#FFF8DE',
  light: '#FFE999',
  main: '#FECE21',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FDE3E9',
  light: '#F6C0CD',
  main: '#F1456E',
  dark: '#BE0E39',
  darker: '#7A0916',
  contrastText: '#FFFFFF',
};

const COMMON = {
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.2),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export function palette(mode: 'light' | 'dark') {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
      neutral: GREY[200],
    },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
  };

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.12),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  };

  return mode === 'light' ? light : dark;
}
