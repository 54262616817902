import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import {
  acceptReport,
  addUser,
  assignLicenses,
  changeAdmin,
  changeProfilePassword,
  ChartExpenseByYearResponse,
  createCategory,
  createClient,
  createCostCenter,
  createEnvironment,
  createJobOrder,
  createTag,
  deleteCategory,
  deleteClient,
  deleteCostCenter,
  deleteEnvironment,
  deleteJobOrder,
  deleteReport,
  deleteTag,
  deleteUser,
  disableUser,
  enableUser,
  getAllCategories,
  getAllClients,
  getAllCostCenters,
  getAllCurrencies,
  getAllEnvironments,
  getAllJobOrders,
  getAllReports,
  getAllTags,
  getCategoriesCount,
  getCategoryTags,
  getChartExpensesByCategory,
  getChartExpensesByYear,
  getClient,
  getClientsCount,
  getCostCenter,
  getCostCentersCount,
  getCurrency,
  getEnvironment,
  getEnvironmentsCount,
  getJobOrder,
  getJobOrdersCount,
  getReport,
  getReportFees,
  getReportPdf,
  getReportsCount,
  getTag,
  getTagsCount,
  getUser,
  getUserByCode,
  getUserReports,
  getUsers,
  getUsersCount,
  importFromExcel,
  inviteUser,
  me,
  rejectReport,
  removeUserOverrides,
  resendInvitation,
  resetPassword,
  updateCategory,
  updateClient,
  updateCostCenter,
  updateEnvironment,
  updateEnvironmentLogo,
  updateJobOrder,
  updateProfile,
  updateReportFee,
  updateTag,
  updateUser,
  updateUserOverrides,
} from 'src/api';

import {
  getChangePaymentMethodUri,
  getCheckoutUri,
  getSubscriptions,
} from 'src/api/subscriptions-api';
import ApiError from 'src/models/api-error';
import Category from 'src/models/category';
import ChartExpenseByCategory from 'src/models/chart-expense-by-category';
import Client from 'src/models/client';
import CostCenter from 'src/models/cost-center';
import Currency from 'src/models/currency';
import Environment from 'src/models/environment';
import JobOrder from 'src/models/job-order';
import NewEditUser from 'src/models/new-edit-user';
import Report from 'src/models/report';
import ReportFee from 'src/models/report-fee';
import Tag from 'src/models/tag';
import User, { Me, UserOverrides } from 'src/models/user';
import UsersList from 'src/models/users-list';
import { AuthUser } from 'src/types/types';

// Dashboard api
export const useGetChartExpensesByYear = (
  environmentId: string | number | undefined,
  options?: UseQueryOptions<
    ChartExpenseByYearResponse | undefined,
    unknown,
    ChartExpenseByYearResponse | undefined,
    [string, string | number | undefined]
  >
): UseQueryResult<ChartExpenseByYearResponse | undefined, unknown> =>
  useQuery<
    ChartExpenseByYearResponse | undefined,
    unknown,
    ChartExpenseByYearResponse | undefined,
    [string, string | number | undefined]
  >({
    queryKey: ['chartExpensesByYear', environmentId],
    queryFn: () => {
      if (environmentId === undefined) {
        return Promise.resolve(undefined);
      }
      return getChartExpensesByYear(environmentId);
    },
    initialData: undefined,
    ...options,
  });

export const useGetChartExpensesByCategory = (
  environmentId: string | number | undefined,
  year: string | number | undefined,
  options?: UseQueryOptions<
    ChartExpenseByCategory[] | undefined,
    unknown,
    ChartExpenseByCategory[] | undefined,
    [string, string | number | undefined, string | number | undefined]
  >
): UseQueryResult<ChartExpenseByCategory[] | undefined, unknown> =>
  useQuery<
    ChartExpenseByCategory[] | undefined,
    unknown,
    ChartExpenseByCategory[] | undefined,
    [string, string | number | undefined, string | number | undefined]
  >({
    queryKey: ['chartExpensesByCategory', environmentId, year],
    queryFn: () => {
      if (environmentId === undefined) {
        return Promise.resolve(undefined);
      }
      return getChartExpensesByCategory(environmentId, year);
    },
    initialData: [],
    ...options,
  });

// Reports api
export const useGetReportsCount = (
  {
    environmentId,
    from,
    to,
    name,
    status,
    userId,
  }: {
    environmentId: number | undefined;
    from?: string;
    to?: string;
    name?: string;
    status?: number | undefined;
    userId?: string | number | undefined;
  },
  options?: UseQueryOptions<
    { total: number; sumValue: number },
    unknown,
    { total: number; sumValue: number },
    [
      string,
      number | undefined,
      string | undefined,
      string | undefined,
      string | undefined,
      number | undefined | string | undefined,
      string | number | undefined
    ]
  >
): UseQueryResult<{ total: number; sumValue: number }, unknown> =>
  useQuery<
    { total: number; sumValue: number },
    unknown,
    { total: number; sumValue: number },
    [
      string,
      number | undefined,
      string | undefined,
      string | undefined,
      string | undefined,
      number | undefined | string | undefined,
      string | number | undefined
    ]
  >({
    queryKey: ['reports_count', environmentId, from, to, name, status, userId],
    queryFn: () => {
      if (environmentId === undefined) {
        return Promise.resolve({ total: 0, sumValue: 0 });
      }

      return getReportsCount(environmentId, from, to, name, status, userId);
    },
    ...options,
  });

export const useGetAllReports = (
  {
    environmentId,
    limit,
    from,
    to,
    page,
    name,
    status,
    orderBy,
    orderType,
    userId,
  }: {
    environmentId?: number;
    from?: string;
    to?: string;
    limit?: number;
    page?: number;
    name?: string;
    status?: number;
    orderBy?: string;
    orderType?: string;
    userId?: number | string;
  },
  options?: UseQueryOptions<
    Report[],
    unknown,
    Report[],
    [
      string,
      number | undefined,
      number | undefined,
      number | undefined,
      string | undefined,
      string | undefined,
      string | undefined,
      number | undefined,
      string | undefined,
      string | undefined,
      number | string | undefined
    ]
  >
): UseQueryResult<Report[], unknown> =>
  useQuery<
    Report[],
    unknown,
    Report[],
    [
      string,
      number | undefined,
      number | undefined,
      number | undefined,
      string | undefined,
      string | undefined,
      string | undefined,
      number | undefined,
      string | undefined,
      string | undefined,
      number | string | undefined
    ]
  >({
    queryKey: [
      'reports',
      environmentId,
      limit,
      page,
      from,
      to,
      name,
      status,
      orderBy,
      orderType,
      userId,
    ],
    queryFn: () => {
      if (environmentId === undefined) {
        return Promise.resolve([]);
      }
      return getAllReports(
        environmentId,
        limit,
        page,
        from,
        to,
        name,
        status,
        orderBy,
        orderType,
        userId
      );
    },
    ...options,
  });

export const useGetReport = (
  reportId?: number | string,
  options?: UseQueryOptions<
    Report | undefined,
    unknown,
    Report | undefined,
    [string, number | string | undefined]
  >
): UseQueryResult<Report | undefined, unknown> =>
  useQuery<Report | undefined, unknown, Report | undefined, [string, number | string | undefined]>({
    queryKey: ['reports', reportId],
    queryFn: () => {
      if (!reportId || options?.enabled === false) {
        return Promise.resolve(undefined);
      }

      return getReport(reportId);
    },
    initialData: undefined,
    ...options,
  });
export const useGetReportFees = (
  reportId: number,
  options?: UseQueryOptions<ReportFee[], unknown, ReportFee[], [string, number]>
): UseQueryResult<ReportFee[], unknown> =>
  useQuery<ReportFee[], unknown, ReportFee[], [string, number]>({
    queryKey: ['reportFees', reportId],
    queryFn: () => {
      if (options?.enabled === false) {
        return Promise.resolve([]);
      }

      return getReportFees(reportId);
    },
    initialData: [],
    ...options,
  });

export const useGetUserReports = (
  userId: string | number,
  environmentId?: string | number,
  options?: UseQueryOptions<
    Report[],
    unknown,
    Report[],
    [string, number | string, number | string | undefined]
  >
) =>
  useQuery<Report[], unknown, Report[], [string, number | string, number | string | undefined]>({
    queryKey: ['reports', userId, environmentId],
    queryFn: () => {
      if (!environmentId) {
        return Promise.resolve([]);
      }
      return getUserReports(userId, environmentId);
    },
    initialData: [],
    ...options,
  });
export const useAcceptReport = () => {
  const queryClient = useQueryClient();

  return useMutation((reportId: string | number) => acceptReport(reportId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['reports']);
      queryClient.invalidateQueries(['reportFees']);
      queryClient.invalidateQueries(['reports_count']);
    },
    onError: (error) => {
      console.error('Error rejecting report:', error);
    },
  });
};
export const useRejectReport = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ reportId, notes = '' }: { reportId: string | number; notes: string }) =>
      rejectReport(reportId, notes),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(['reports']);
        void queryClient.invalidateQueries(['reportFees']);
        queryClient.invalidateQueries(['reports_count']);
      },
    }
  );
};
export const useDeleteReport = () => {
  const queryClient = useQueryClient();

  return useMutation((reportId: string | number) => deleteReport(reportId), {
    onSuccess: () => {
      void queryClient.invalidateQueries(['reports']);
      void queryClient.invalidateQueries(['reportFees']);
    },
  });
};

/// ----------------------------
export const useUpdateReportFee = (
  onSuccess?: VoidFunction,
  onError?: (error: ApiError) => void
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ reportId, fee }: { reportId: string | number; fee: ReportFee }) =>
      updateReportFee(reportId, fee),
    {
      onSuccess: (data, variables) => {
        const { reportId } = variables;

        void queryClient.invalidateQueries(['reportFees', reportId]);

        onSuccess?.();
      },
      onError,
    }
  );
};

export const useGetReportPdf = (reportId: number | string) =>
  useQuery({ queryKey: ['reports', reportId, 'pdf'], queryFn: () => getReportPdf(reportId) });

// Users api
export const useGetUser = (environmentId: number | string, id: number | string) =>
  useQuery({
    queryKey: ['users', id, environmentId],
    queryFn: () => {
      if (environmentId === 0 || id === 0) {
        return undefined;
      }
      return getUser(environmentId, id);
    },
  });

export const useGetUsersCount = (
  {
    environmentId,
    name,
    status,
  }: {
    environmentId?: number;
    name?: string;
    status?: number;
  },
  options?: UseQueryOptions<
    number,
    unknown,
    number,
    [string, number | undefined, string | undefined, number | undefined]
  >
) =>
  useQuery<
    number,
    unknown,
    number,
    [string, number | undefined, string | undefined, number | undefined]
  >({
    queryKey: ['users_count', environmentId || 0, name || '', status],
    queryFn: () => {
      if (!environmentId) {
        return Promise.resolve(0);
      }
      return getUsersCount(environmentId, name, status);
    },
    ...options,
  });

export const useGetAllUsers = (
  {
    environmentId,
    name,
    status,
    orderBy,
    orderType,
    limit,
    page,
  }: {
    environmentId?: number;
    name?: string;
    status?: number;
    orderBy?: string;
    orderType?: string;
    limit?: number;
    page?: number;
  },
  options?: UseQueryOptions<
    UsersList,
    unknown,
    UsersList,
    [
      string,
      number | undefined,
      string | undefined,
      number | undefined,
      string | undefined,
      string | undefined,
      number | undefined,
      number | undefined
    ]
  >
) =>
  useQuery<
    UsersList,
    unknown,
    UsersList,
    [
      string,
      number | undefined,
      string | undefined,
      number | undefined,
      string | undefined,
      string | undefined,
      number | undefined,
      number | undefined
    ]
  >({
    queryKey: [
      'users',
      environmentId || 0,
      name || '',
      status,
      orderBy || '',
      orderType || '',
      limit || 10,
      page || 0,
    ],
    queryFn: () => {
      if (!environmentId) {
        return Promise.resolve([]);
      }
      return getUsers(environmentId, name, status, orderBy, orderType, limit, page);
    },
    ...options,
  });

export const useAddUser = (
  environmentId: string | number,
  onSuccess?: VoidFunction,
  onError?: (error: ApiError) => void
) => {
  const queryClient = useQueryClient();

  return useMutation((user: NewEditUser) => addUser(environmentId, user), {
    onSuccess: () => {
      void queryClient.invalidateQueries(['users']);
      onSuccess?.();
    },
    onError,
  });
};

export const useInviteUser = (onSuccess?: VoidFunction, onError?: (error: ApiError) => void) => {
  const queryClient = useQueryClient();

  return useMutation(inviteUser, {
    onSuccess: () => {
      void queryClient.invalidateQueries(['users']);
      onSuccess?.();
    },
    onError,
  });
};
export const useResendInvitation = (
  onSuccess?: VoidFunction,
  onError?: (error: ApiError) => void
) => {
  const queryClient = useQueryClient();

  return useMutation(resendInvitation, {
    onSuccess: () => {
      void queryClient.invalidateQueries(['users']);
      onSuccess?.();
    },
    onError,
  });
};
export const useDisableUser = (onSuccess?: VoidFunction, onError?: (error: ApiError) => void) => {
  const queryClient = useQueryClient();

  return useMutation(disableUser, {
    onSuccess: () => {
      void queryClient.invalidateQueries(['users']);
      onSuccess?.();
    },
    onError,
  });
};
export const useEnableUser = (onSuccess?: VoidFunction, onError?: (error: ApiError) => void) => {
  const queryClient = useQueryClient();

  return useMutation(enableUser, {
    onSuccess: () => {
      void queryClient.invalidateQueries(['users']);
      onSuccess?.();
    },
    onError,
  });
};
export const useImportFromExcel = (
  onSuccess?: VoidFunction,
  onError?: (error: ApiError) => void
) => {
  const queryClient = useQueryClient();

  return useMutation(importFromExcel, {
    onSuccess: () => {
      void queryClient.invalidateQueries(['users']);
      onSuccess?.();
    },
    onError,
  });
};
export const useUpdateUser = (onSuccess?: VoidFunction, onError?: (error: ApiError) => void) => {
  const queryClient = useQueryClient();

  return useMutation((user: User) => updateUser(user), {
    onSuccess: () => {
      void queryClient.invalidateQueries(['users']);
      onSuccess?.();
    },
    onError,
  });
};
export const useUpdateUserOverrides = (
  onSuccess?: VoidFunction,
  onError?: (error: ApiError) => void
) => {
  const queryClient = useQueryClient();
  let _userId: string | number = 0;
  let _environmentId: string | number = 0;

  return useMutation(
    ({ environmentId, user }: { environmentId: number | string; user: UserOverrides }) => {
      _userId = user.id;
      _environmentId = environmentId;
      return updateUserOverrides(environmentId, user);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(['users', _userId, _environmentId]);
        onSuccess?.();
      },
      onError,
    }
  );
};
export const useRemoveUserOverrides = (
  onSuccess?: VoidFunction,
  onError?: (error: ApiError) => void
) => {
  const queryClient = useQueryClient();
  let _userId: string | number = 0;
  let _environmentId: string | number = 0;

  return useMutation(
    ({ environmentId, user }: { environmentId: number | string; user: UserOverrides }) => {
      _userId = user.id;
      _environmentId = environmentId;
      return removeUserOverrides(environmentId, _userId);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(['users', _userId, _environmentId]);
        onSuccess?.();
      },
      onError,
    }
  );
};
export const useDeleteUser = (onSuccess?: VoidFunction, onError?: (error: ApiError) => void) => {
  const queryClient = useQueryClient();

  return useMutation(deleteUser, {
    onSuccess: () => {
      void queryClient.invalidateQueries(['users']);
      onSuccess?.();
    },
    onError,
  });
};

// Environments
export const useGetEnvironment = (
  id?: number | string,
  options?: UseQueryOptions<
    Environment | undefined,
    unknown,
    Environment | undefined,
    [string, number | string | undefined]
  >
): UseQueryResult<Environment | undefined, unknown> =>
  useQuery<
    Environment | undefined,
    unknown,
    Environment | undefined,
    [string, number | string | undefined]
  >({
    queryKey: ['environments', id],
    queryFn: () => {
      if (id === undefined) {
        return Promise.resolve(undefined);
      }
      return getEnvironment(id);
    },
    initialData: undefined,
    ...options,
  });

export const useGetEnvironmentsCount = (
  {
    name,
  }: {
    name?: string;
  } = {},
  options?: UseQueryOptions<number, unknown, number, [string, string]>
) =>
  useQuery({
    queryKey: ['environments_count', name || ''],
    queryFn: () => getEnvironmentsCount(name),
    ...options,
  });

export const useGetAllEnvironments = (
  {
    name,
    orderBy,
    orderType,
    limit,
    page,
  }: {
    name?: string;
    orderBy?: string;
    orderType?: string;
    limit?: number;
    page?: number;
  } = {},
  options?: UseQueryOptions<
    Environment[],
    unknown,
    Environment[],
    [string, string, string, string, number, number]
  >
) =>
  useQuery({
    queryKey: ['environments', name || '', orderBy || '', orderType || '', limit || 10, page || 0],
    queryFn: () => getAllEnvironments(name, orderBy, orderType, limit, page),
    ...options,
  });

export const useCreateEnvironment = () => {
  const queryClient = useQueryClient();

  return useMutation((environment: Environment) => createEnvironment(environment), {
    onSuccess: () => {
      void queryClient.invalidateQueries(['environments']);
    },
  });
};
export const useUpdateEnvironment = () => {
  const queryClient = useQueryClient();

  return useMutation((environment: Environment) => updateEnvironment(environment), {
    onSuccess: () => {
      void queryClient.invalidateQueries(['environments']);
    },
  });
};
export const useUpdateEnvironmentLogo = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, logo }: { id: number | string; logo: File }) => updateEnvironmentLogo(id, logo),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(['environments']);
      },
    }
  );
};
export const useDeleteEnvironment = () => {
  const queryClient = useQueryClient();

  return useMutation((environment: Environment) => deleteEnvironment(environment.id), {
    onSuccess: () => {
      void queryClient.invalidateQueries(['environments']);
    },
  });
};
export const useChangeAdmin = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, id_admin }: { id: number | string; id_admin: number | string }) =>
      changeAdmin(id, id_admin),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(['environments']);
      },
    }
  );
};
export const useAssignLicenses = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      id,
      n_licenze,
      n_licenze_usate,
    }: {
      id: number | string;
      n_licenze: number;
      n_licenze_usate: number;
    }) => assignLicenses(id, n_licenze),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(['environments']);
      },
    }
  );
};

// Currencies
export const useGetCurrency = (
  id: number | string | undefined,
  options?: UseQueryOptions<
    Currency | undefined,
    unknown,
    Currency | undefined,
    [string, number | string | undefined]
  >
): UseQueryResult<Currency | undefined, unknown> =>
  useQuery<
    Currency | undefined,
    unknown,
    Currency | undefined,
    [string, number | string | undefined]
  >({
    queryKey: ['currencies', id],
    queryFn: () => {
      if (id === undefined) {
        return Promise.resolve(undefined);
      }

      return getCurrency(id);
    },
    initialData: undefined,
    ...options,
  });
export const useGetAllCurrencies = () =>
  useQuery({ queryKey: ['currencies'], queryFn: getAllCurrencies, initialData: [] });

// Categories
export const useGetCategoriesCount = (
  {
    environmentId,
    name,
    documents,
  }: {
    environmentId?: number;
    name?: string;
    documents?: number;
  },
  options?: UseQueryOptions<
    number,
    unknown,
    number,
    [string, number | undefined, string | undefined, number | undefined]
  >
) =>
  useQuery<
    number,
    unknown,
    number,
    [string, number | undefined, string | undefined, number | undefined]
  >({
    queryKey: ['categories_count', environmentId || 0, name || '', documents],
    queryFn: () => {
      if (environmentId === undefined || environmentId === null) {
        return Promise.resolve(0);
      }
      return getCategoriesCount(environmentId, name, documents);
    },
    ...options,
  });

export const useGetAllCategories = (
  {
    environmentId,
    name,
    orderBy,
    orderType,
    limit,
    page,
    documents,
  }: {
    environmentId?: number;
    name?: string;
    orderBy?: string;
    orderType?: string;
    limit?: number;
    page?: number;
    documents?: number;
  },
  options?: UseQueryOptions<
    Category[],
    unknown,
    Category[],
    [
      string,
      number | string | undefined,
      string | undefined,
      string | undefined,
      string | undefined,
      number | undefined,
      number | undefined,
      number | undefined
    ]
  >
) =>
  useQuery<
    Category[],
    unknown,
    Category[],
    [
      string,
      number | string | undefined,
      string | undefined,
      string | undefined,
      string | undefined,
      number | undefined,
      number | undefined,
      number | undefined
    ]
  >({
    queryKey: [
      'categories',
      environmentId || 0,
      name || '',
      orderBy || '',
      orderType || '',
      limit || 10,
      page || 0,
      documents || -1,
    ],
    queryFn: () => {
      if (!environmentId) {
        return Promise.resolve([]);
      }
      return getAllCategories(environmentId, name, orderBy, orderType, limit, page, documents);
    },
    ...options,
  });

export const useCreateCategory = (
  environmentId: number | string,
  options?: UseMutationOptions<Category, unknown, Category>
) => {
  const queryClient = useQueryClient();

  return useMutation((category: Category) => createCategory(environmentId, category), {
    onSuccess: () => {
      void queryClient.invalidateQueries(['categories']);
    },
    ...options,
  });
};
export const useUpdateCategory = () => {
  const queryClient = useQueryClient();

  return useMutation((category: Category) => updateCategory(category), {
    onSuccess: () => {
      void queryClient.invalidateQueries(['categories']);
    },
  });
};
export const useDeleteCategory = () => {
  const queryClient = useQueryClient();

  return useMutation((category: Category) => deleteCategory(category.id), {
    onSuccess: () => {
      void queryClient.invalidateQueries(['categories']);
    },
  });
};
export const useGetCategoryTags = (
  categoryId?: number | string,
  options?: UseQueryOptions<Tag[], unknown, Tag[], [string, number | string | undefined, string]>
) =>
  useQuery<Tag[], unknown, Tag[], [string, number | string | undefined, string]>({
    queryKey: ['categories', categoryId, 'tags'],
    queryFn: () => {
      if (categoryId === undefined) {
        return Promise.resolve([]);
      }
      return getCategoryTags(categoryId);
    },
    initialData: [],
    ...options,
  });

// Tags
export const useGetTag = (id: number | string) =>
  useQuery({ queryKey: ['tags', id], queryFn: () => getTag(id) });

export const useGetTagsCount = (
  {
    environmentId,
    name,
    type,
    hasdate,
  }: {
    environmentId?: number;
    name?: string;
    type?: number;
    hasdate?: number;
  },
  options?: UseQueryOptions<
    number,
    unknown,
    number,
    [string, number | undefined, string | undefined, number | undefined, number | undefined]
  >
) =>
  useQuery<
    number,
    unknown,
    number,
    [string, number | undefined, string | undefined, number | undefined, number | undefined]
  >({
    queryKey: ['tags', environmentId, name, type, hasdate],
    queryFn: () => {
      if (!environmentId) {
        return Promise.resolve(0);
      }
      return getTagsCount(environmentId, name, type, hasdate);
    },
    ...options,
  });

export const useGetAllTags = (
  {
    environmentId,
    name,
    orderBy,
    orderType,
    limit,
    page,
    type,
    hasdate,
  }: {
    environmentId?: number;
    name?: string;
    orderBy?: string;
    orderType?: string;
    limit?: number;
    page?: number;
    type?: number;
    hasdate?: number;
  },
  options?: UseQueryOptions<
    Tag[],
    unknown,
    Tag[],
    [
      string,
      number | undefined,
      string | undefined,
      string | undefined,
      string | undefined,
      number | undefined,
      number | undefined,
      number | undefined,
      number | undefined
    ]
  >
) =>
  useQuery<
    Tag[],
    unknown,
    Tag[],
    [
      string,
      number | undefined,
      string | undefined,
      string | undefined,
      string | undefined,
      number | undefined,
      number | undefined,
      number | undefined,
      number | undefined
    ]
  >({
    queryKey: ['tags', environmentId, name, orderBy, orderType, limit, page, type, hasdate],
    queryFn: () => {
      if (!environmentId) {
        return Promise.resolve([]);
      }
      return getAllTags(environmentId, name, orderBy, orderType, limit, page, type, hasdate);
    },
    ...options,
  });

export const useCreateTag = (
  environmentId: number | string,
  options?: UseMutationOptions<Tag, unknown, Tag>
) => {
  const queryClient = useQueryClient();

  return useMutation((tag: Tag) => createTag(environmentId, tag), {
    onSuccess: (newTag) => {
      void queryClient.invalidateQueries(['tags', environmentId]);
    },
    ...options,
  });
};
export const useUpdateTag = () => {
  const queryClient = useQueryClient();

  return useMutation((tag: Tag) => updateTag(tag), {
    onSuccess: () => {
      void queryClient.invalidateQueries(['tags']);
    },
  });
};
export const useDeleteTag = () => {
  const queryClient = useQueryClient();

  return useMutation((tag: Tag) => deleteTag(tag.id), {
    onSuccess: () => {
      void queryClient.invalidateQueries(['tags']);
    },
  });
};

// Job orders
export const useGetJobOrder = (id: number | string) =>
  useQuery({ queryKey: ['jobOrders', id], queryFn: () => getJobOrder(id) });

export const useGetJobOrdersCount = (
  {
    environmentId,
    name,
  }: {
    environmentId?: number;
    name?: string;
  },
  options?: UseQueryOptions<
    number,
    unknown,
    number,
    [string, number | undefined, string | undefined]
  >
) =>
  useQuery<number, unknown, number, [string, number | undefined, string | undefined]>({
    queryKey: ['jobOrders_count', environmentId || 0, name || ''],
    queryFn: () => {
      if (environmentId === undefined || environmentId === null) {
        return Promise.resolve(0);
      }
      return getJobOrdersCount(environmentId, name);
    },
    ...options,
  });

export const useGetAllJobOrders = (
  {
    environmentId,
    name,
    orderBy,
    orderType,
    limit,
    page,
  }: {
    environmentId?: number;
    name?: string;
    orderBy?: string;
    orderType?: string;
    limit?: number;
    page?: number;
  },
  options?: UseQueryOptions<
    JobOrder[],
    unknown,
    JobOrder[],
    [
      string,
      number | undefined,
      string | undefined,
      string | undefined,
      string | undefined,
      number | undefined,
      number | undefined
    ]
  >
) =>
  useQuery<
    JobOrder[],
    unknown,
    JobOrder[],
    [
      string,
      number | undefined,
      string | undefined,
      string | undefined,
      string | undefined,
      number | undefined,
      number | undefined
    ]
  >({
    queryKey: ['jobOrders', environmentId, name, orderBy, orderType, limit, page],
    queryFn: () => {
      if (!environmentId) {
        return Promise.resolve([]);
      }
      return getAllJobOrders(environmentId, name, orderBy, orderType, limit, page);
    },
  });

export const useCreateJobOrder = (
  environmentId: string | number,
  onSuccess: VoidFunction,
  onError: (error: ApiError) => void
) => {
  const queryClient = useQueryClient();

  return useMutation((jobOrder: JobOrder) => createJobOrder(environmentId, jobOrder), {
    onSuccess: () => {
      void queryClient.invalidateQueries(['jobOrders']);
      if (onSuccess) onSuccess();
    },
    onError: (error: ApiError) => {
      if (error) onError(error);
    },
  });
};
export const useUpdateJobOrder = (onSuccess: VoidFunction, onError: (error: ApiError) => void) => {
  const queryClient = useQueryClient();

  return useMutation((jobOrder: JobOrder) => updateJobOrder(jobOrder), {
    onSuccess: () => {
      void queryClient.invalidateQueries(['jobOrders']);
      if (onSuccess) onSuccess();
    },
    onError: (error: ApiError) => {
      if (error) onError(error);
    },
  });
};
export const useDeleteJobOrder = (onSuccess: VoidFunction, onError: (error: ApiError) => void) => {
  const queryClient = useQueryClient();

  return useMutation((jobOrder: JobOrder) => deleteJobOrder(jobOrder.id), {
    onSuccess: () => {
      void queryClient.invalidateQueries(['jobOrders']);
      if (onSuccess) onSuccess();
    },
    onError: (error: ApiError) => {
      if (error) onError(error);
    },
  });
};

// Clients
export const useGetClient = (id: number | string) =>
  useQuery({ queryKey: ['clients', id], queryFn: () => getClient(id) });

export const useGetClientsCount = (
  {
    environmentId,
    name,
  }: {
    environmentId?: number;
    name?: string;
  },
  options?: UseQueryOptions<
    number,
    unknown,
    number,
    [string, number | undefined, string | undefined]
  >
) =>
  useQuery<number, unknown, number, [string, number | undefined, string | undefined]>({
    queryKey: ['clients_count', environmentId || 0, name || ''],
    queryFn: () => {
      if (environmentId === undefined || environmentId === null) {
        return Promise.resolve(0);
      }
      return getClientsCount(environmentId, name);
    },
    ...options,
  });

export const useGetAllClients = (
  {
    environmentId,
    name,
    orderBy,
    orderType,
    limit,
    page,
  }: {
    environmentId?: number;
    name?: string;
    orderBy?: string;
    orderType?: string;
    limit?: number;
    page?: number;
  },
  options?: UseQueryOptions<
    Client[],
    unknown,
    Client[],
    [
      string,
      number | undefined,
      string | undefined,
      string | undefined,
      string | undefined,
      number | undefined,
      number | undefined
    ]
  >
) =>
  useQuery<
    Client[],
    unknown,
    Client[],
    [
      string,
      number | undefined,
      string | undefined,
      string | undefined,
      string | undefined,
      number | undefined,
      number | undefined
    ]
  >({
    queryKey: [
      'clients',
      environmentId || 0,
      name || '',
      orderBy || '',
      orderType || '',
      limit || 10,
      page || 0,
    ],
    queryFn: () => {
      if (!environmentId) {
        return Promise.resolve([]);
      }
      return getAllClients(environmentId, name, orderBy, orderType, limit, page);
    },
    ...options,
  });

export const useCreateClient = (environmentId: string | number) => {
  const queryClient = useQueryClient();

  return useMutation((client: Client) => createClient(environmentId, client), {
    onSuccess: () => {
      void queryClient.invalidateQueries(['clients']);
    },
  });
};
export const useUpdateClient = (onSuccess?: VoidFunction, onError?: (error: ApiError) => void) => {
  const queryClient = useQueryClient();

  return useMutation((client: Client) => updateClient(client), {
    onSuccess: () => {
      void queryClient.invalidateQueries(['clients']);
      onSuccess?.();
    },
    onError,
  });
};
export const useDeleteClient = (onSuccess?: VoidFunction, onError?: (error: ApiError) => void) => {
  const queryClient = useQueryClient();

  return useMutation((client: Client) => deleteClient(client.id), {
    onSuccess: () => {
      void queryClient.invalidateQueries(['clients']);
      onSuccess?.();
    },
    onError,
  });
};

// Cost Centers
export const useGetCostCentersCount = (
  {
    environmentId,
    name,
  }: {
    environmentId?: number;
    name?: string;
  },
  options?: UseQueryOptions<
    number,
    unknown,
    number,
    [string, number | undefined, string | undefined]
  >
) =>
  useQuery<number, unknown, number, [string, number | undefined, string | undefined]>({
    queryKey: ['costCenters_count', environmentId || 0, name || ''],
    queryFn: () => {
      if (!environmentId) {
        return Promise.resolve(0);
      }
      return getCostCentersCount(environmentId, name);
    },
    ...options,
  });

export const useGetAllCostCenters = (
  {
    environmentId,
    name,
    orderBy,
    orderType,
    limit,
    page,
  }: {
    environmentId?: number;
    name?: string;
    orderBy?: string;
    orderType?: string;
    limit?: number;
    page?: number;
  },
  options?: UseQueryOptions<
    CostCenter[],
    unknown,
    CostCenter[],
    [
      string,
      number | undefined,
      string | undefined,
      string | undefined,
      string | undefined,
      number | undefined,
      number | undefined
    ]
  >
) =>
  useQuery<
    CostCenter[],
    unknown,
    CostCenter[],
    [
      string,
      number | undefined,
      string | undefined,
      string | undefined,
      string | undefined,
      number | undefined,
      number | undefined
    ]
  >({
    queryKey: [
      'costCenters',
      environmentId || 0,
      name || '',
      orderBy || '',
      orderType || '',
      limit || 10,
      page || 0,
    ],
    queryFn: () => {
      if (!environmentId) {
        return Promise.resolve([]);
      }
      return getAllCostCenters(environmentId, name, orderBy, orderType, limit, page);
    },
    ...options,
  });

export const useGetCostCenter = (id: number | string) =>
  useQuery({ queryKey: ['costCenters', id], queryFn: () => getCostCenter(id) });

export const useCreateCostCenter = (environmentId: string | number) => {
  const queryClient = useQueryClient();

  return useMutation((costCenter: CostCenter) => createCostCenter(environmentId, costCenter), {
    onSuccess: () => {
      void queryClient.invalidateQueries(['costCenters']);
    },
  });
};

export const useUpdateCostCenter = (
  onSuccess?: VoidFunction,
  onError?: (error: ApiError) => void
) => {
  const queryClient = useQueryClient();

  return useMutation((costCenter: CostCenter) => updateCostCenter(costCenter), {
    onSuccess: () => {
      void queryClient.invalidateQueries(['costCenters']);
      onSuccess?.();
    },
    onError,
  });
};

export const useDeleteCostCenter = (
  onSuccess?: VoidFunction,
  onError?: (error: ApiError) => void
) => {
  const queryClient = useQueryClient();

  return useMutation((costCenter: CostCenter) => deleteCostCenter(costCenter.id), {
    onSuccess: () => {
      void queryClient.invalidateQueries(['costCenters']);
      onSuccess?.();
    },
    onError,
  });
};

export const useGetUserByCode = (
  code?: string,
  is_invitation?: boolean,
  options?: UseQueryOptions<
    AuthUser | undefined,
    unknown,
    AuthUser | undefined,
    [string, string, string | undefined]
  >,
  onSuccess?: VoidFunction,
  onError?: (error: ApiError) => void
): UseQueryResult<AuthUser | undefined, unknown> =>
  useQuery<
    AuthUser | undefined,
    unknown,
    AuthUser | undefined,
    [string, string, string | undefined]
  >({
    queryKey: ['auth', 'getUserByCode', code],
    queryFn: () => {
      if (!code) {
        return Promise.resolve(undefined);
      }
      return getUserByCode(code, is_invitation);
    },
    onSuccess,
    onError: (error) => {
      if (error) onError?.(error as ApiError);
    },
    initialData: undefined,
    ...options,
  });

// Subscriptions
export const useGetSubscriptions = () =>
  useQuery({
    queryKey: ['subscriptions'],
    queryFn: () => getSubscriptions(),
  });
export const useGetCheckoutUri = (
  subscriptionId: string,
  redirectUrl: string,
  options?: UseQueryOptions<string, unknown, string, [string, string, string]>
) =>
  useQuery<string, unknown, string, [string, string, string]>({
    queryKey: ['checkout-uri', subscriptionId, redirectUrl],
    queryFn: () => getCheckoutUri(subscriptionId, redirectUrl),
    initialData: '',
    ...options,
  });
export const useGetChangePaymentMethodUri = (
  subscriptionId: string,
  redirectUrl: string,
  options?: UseQueryOptions<string, unknown, string, [string, string, string]>
) =>
  useQuery<string, unknown, string, [string, string, string]>({
    queryKey: ['change-payment-method-uri', subscriptionId, redirectUrl],
    queryFn: () => getChangePaymentMethodUri(subscriptionId, redirectUrl),
    initialData: '',
    ...options,
  });

// Profile
export const useUpdateProfile = (onSuccess?: VoidFunction, onError?: (error: ApiError) => void) => {
  const queryClient = useQueryClient();

  return useMutation(updateProfile, {
    onSuccess: () => {
      void queryClient.invalidateQueries(['profile']);
      onSuccess?.();
    },
    onError,
  });
};
export const useChangeProfilePassword = (
  onSuccess?: VoidFunction,
  onError?: (error: ApiError) => void
) =>
  useMutation(changeProfilePassword, {
    onSuccess,
    onError,
  });
export const useGetMe = (options?: UseQueryOptions<Me, unknown, Me, [string]>) =>
  useQuery<Me, unknown, Me, [string]>({
    queryKey: ['profile'],
    queryFn: me,
    initialData: undefined,
    ...options,
  });

// Authentication
export const useResetPassword = (onSuccess?: VoidFunction, onError?: (error: ApiError) => void) =>
  useMutation(resetPassword, {
    onSuccess,
    onError,
  });
