import Client from 'src/models/client';

export const Clients: Client[] = [
  {
    id: 16,
    name: 'Mario Fees',
    environmentID: 6,
    status: 1,
    indirizzo: 'Cremona',
    codice: '0000',
    code: 14,
    totalValue: 283.01,
    n_fees: 5,
  },
  {
    id: 17,
    name: 'Giovanni Fees',
    environmentID: 6,
    status: 1,
    indirizzo: 'Modena',
    codice: '0999a',
    code: 15,
    totalValue: 28.9,
    n_fees: 3,
  },
  {
    id: 296,
    name: 'test',
    environmentID: 6,
    status: 1,
    indirizzo: 'indirizzo di prova',
    codice: 'test',
    code: 18,
    totalValue: 0.0,
    n_fees: 0,
  },
  {
    id: 297,
    name: 'test2',
    environmentID: 6,
    status: 1,
    indirizzo: 'indirizzo di prova',
    codice: 'test2',
    code: 19,
    totalValue: 20.0,
    n_fees: 1,
  },
  {
    id: 1071,
    name: 'Marco Verdi',
    environmentID: 6,
    status: 1,
    indirizzo: 'Via Colli 3, Mantova',
    codice: '0008',
    code: 1041,
    totalValue: 10.0,
    n_fees: 1,
  },
  {
    id: 1074,
    name: 'Cliente X',
    environmentID: 6,
    status: 1,
    indirizzo: "Via Dell'Innovazione Digitale 3",
    codice: '0009',
    code: 1044,
    totalValue: 0.0,
    n_fees: 0,
  },
  {
    id: 1489,
    name: 'nome',
    environmentID: 6,
    status: 1,
    indirizzo: 'via brescia, 28',
    codice: '1',
    code: 1459,
    totalValue: 0.0,
    n_fees: 0,
  },
  {
    id: 1490,
    name: 'test barbara',
    environmentID: 6,
    status: 1,
    indirizzo: 'via',
    codice: '1000',
    code: 1460,
    totalValue: 30.0,
    n_fees: 1,
  },
];
