import apiBase from 'src/api/api-base';
import { endpoints } from 'src/config/endpoints';
import Currency from 'src/models/currency';

export const getCurrency = (id: number | string): Promise<Currency> =>
  new Promise((resolve, reject) => {
    apiBase.get(endpoints.currencies.getCurrency(id)).then((response) => {
      resolve(response.data.Data as Currency);
    });
  });

export const getAllCurrencies = (): Promise<Currency[]> =>
  new Promise((resolve, reject) => {
    apiBase.get(endpoints.currencies.getAll).then((response) => {
      resolve(response.data.Data as Currency[]);
    });
  });
