import { http, delay, HttpResponse } from 'msw';
import { API_BASE_URL } from 'src/api/api-base';
import { endpoints } from 'src/config/endpoints';
import { Clients } from 'src/fixtures/clients';
import Client from 'src/models/client';

export default [
  http.post(API_BASE_URL + endpoints.clients.create, async (req) => {
    await delay('real');

    const client = (await req.request.json()) as Client;
    client.id = Clients.length + 1;
    Clients.push(client);

    return HttpResponse.json(client);
  }),
  http.put(API_BASE_URL + endpoints.clients.updateClient(':clientId'), async (req) => {
    await delay('real');

    const client = (await req.request.json()) as Client;
    const index = Clients.findIndex((c) => c.id === Number(client.id));
    Clients[index] = client;

    return HttpResponse.json(client);
  }),
  http.get(API_BASE_URL + endpoints.clients.getAll, async () => {
    await delay('real');
    return HttpResponse.json(Clients);
  }),
  http.delete(API_BASE_URL + endpoints.clients.delete(':clientId'), async (req) => {
    await delay('real');

    const { clientId } = req.params;
    const index = Clients.findIndex((client) => client.id === Number(clientId));
    Clients.splice(index, 1);

    return HttpResponse.json({});
  }),
];
