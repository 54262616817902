import EnvironmentModel from 'src/models/environment';

export const Environment: EnvironmentModel = {
  id: 6,
  currencyId: '1',
  email: null,
  city: 'Cremona',
  cap: '26100',
  partita_iva: '01699840193',
  logo: '6.png',
  reportsDisclaimer:
    "Si autorizza l'intestatario della Nota Spesa a recarsi presso le sottoindicate localit\u00e0:",
  active_admin: '1',
  id_superadmin: '117',
  superadmin_username: 'FeesAdmin',
  superadmin_company: 'Fees Srl',
  id_admin: '7946',
  admin_username: 'support@fees.world',
  admin_company: 'fees Srl',
  admin_first_name: 'Fees',
  admin_last_name: 'Support',
  admin_email: 'support@fees.world',
  n_licenze: 20,
  n_licenze_usate: 7,
  environmentname: 'fees S.r.l.',
  indirizzo: "Via Dell'Innovazione digitale 3",
  active: true,
};

export const Environments: EnvironmentModel[] = [
  {
    id: 1,
    currencyId: '1',
    email: null,
    city: 'Cremona',
    cap: '26100',
    partita_iva: '01699840193',
    logo: '1.png',
    reportsDisclaimer:
      "Si autorizza l'intestatario della Nota Spesa a recarsi presso le sottoindicate localit\u00e0:",
    active_admin: '1',
    id_superadmin: '117',
    superadmin_username: 'Admin Ciccio',
    superadmin_company: 'Secondo ambiente',
    id_admin: '7946',
    admin_username: '',
    admin_company: 'fees Srl',
    admin_first_name: 'Fees',
    admin_last_name: 'Support',
    admin_email: 'support@fees.world',
    n_licenze: 20,
    n_licenze_usate: 7,
    environmentname: 'Ambiente pazzoide',
    indirizzo: "Via Dell'Innovazione digitale 3",
    active: true,
  },
  {
    id: 2,
    currencyId: '1',
    email: null,
    city: 'Cremona',
    cap: '26100',
    partita_iva: '01699840193',
    logo: '2.png',
    reportsDisclaimer:
      "Si autorizza l'intestatario della Nota Spesa a recarsi presso le sottoindicate localit\u00e0:",
    active_admin: '1',
    id_superadmin: '117',
    superadmin_username: 'FeesAdmin',
    superadmin_company: 'Fees Srl',
    admin_first_name: 'Fees',
    admin_last_name: 'Support',
    admin_email: 'support@fees.world',
    id_admin: '7946',
    admin_username: '',
    admin_company: 'fees Srl',
    n_licenze: 20,
    n_licenze_usate: 7,
    environmentname: 'fees S.r.l.',
    indirizzo: "Via Dell'Innovazione digitale 3",
    active: true,
  },
  {
    id: 3,
    currencyId: '1',
    email: null,
    city: 'Cremona',
    cap: '26100',
    partita_iva: '01699840193',
    logo: '3.png',
    reportsDisclaimer:
      "Si autorizza l'intestatario della Nota Spesa a recarsi presso le sottoindicate localit\u00e0:",
    active_admin: '1',
    id_superadmin: '117',
    superadmin_username: 'Frodo',
    superadmin_company: 'Fees Srl',
    admin_first_name: 'Fees',
    admin_last_name: 'Support',
    admin_email: 'support@fees.world',
    id_admin: '7946',
    admin_username: '',
    admin_company: 'fees Srl',
    n_licenze: 20,
    n_licenze_usate: 7,
    environmentname: "La compagnia dell'anello",
    indirizzo: "Via Dell'Innovazione digitale 3",
    active: true,
  },
];
