import { endpoints } from 'src/config/endpoints';
import apiBase from 'src/api/api-base';
import Client from 'src/models/client';

export const getClient = (id: number | string): Promise<Client> =>
  new Promise((resolve, reject) => {
    apiBase.get(endpoints.clients.getClient(id)).then((response) => {
      resolve(response.data.Data as Client);
    });
  });

export const getClientsCount = (
  environmentId: string | number,
  name: string | undefined
): Promise<number> =>
  new Promise((resolve, reject) => {
    apiBase
      .get(endpoints.clients.count(environmentId), {
        params: { name },
      })
      .then((response) => {
        resolve(parseInt(response.data.Data, 10) || 0);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getAllClients = (
  environmentId: string | number,
  name: string | undefined,
  orderBy: string | undefined,
  orderType: string | undefined,
  limit: number | undefined,
  page: number | undefined
): Promise<Client[]> =>
  new Promise((resolve, reject) => {
    apiBase
      .get(endpoints.clients.getAll(environmentId), {
        params: { name, orderBy, orderType, limit, page },
      })
      .then((response) => {
        resolve(response.data.Data as Client[]);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const deleteClient = (id: number): Promise<Client[]> =>
  new Promise((resolve, reject) => {
    apiBase.delete(endpoints.clients.delete(id)).then((response) => {
      resolve(response.data.Data as Client[]);
    });
  });

export const createClient = (environmentId: string | number, client: Client): Promise<Client[]> =>
  new Promise((resolve, reject) => {
    apiBase
      .post(endpoints.clients.create(environmentId), client)
      .then((response) => {
        resolve(response.data.Data as Client[]);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const updateClient = (client: Client): Promise<Client[]> =>
  new Promise((resolve, reject) => {
    apiBase.put(endpoints.clients.updateClient(client.id), client).then((response) => {
      resolve(response.data.Data as Client[]);
    });
  });
