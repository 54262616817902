import apiBase from 'src/api/api-base';
import { endpoints } from 'src/config/endpoints';
import JobOrder from 'src/models/job-order';

export const getJobOrder = (id: number | string): Promise<JobOrder> =>
  new Promise((resolve, reject) => {
    apiBase.get(endpoints.jobOrders.getJobOrder(id)).then((response) => {
      resolve(response.data.Data as JobOrder);
    });
  });

export const getJobOrdersCount = (
  environmentId: string | number,
  name: string | undefined
): Promise<number> =>
  new Promise((resolve, reject) => {
    apiBase
      .get(endpoints.jobOrders.count(environmentId), {
        params: { name },
      })
      .then((response) => {
        resolve(parseInt(response.data.Data, 10) || 0);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getAllJobOrders = (
  environmentId: string | number,
  name: string | undefined,
  orderBy: string | undefined,
  orderType: string | undefined,
  limit: number | undefined,
  page: number | undefined
): Promise<JobOrder[]> =>
  new Promise((resolve, reject) => {
    apiBase
      .get(endpoints.jobOrders.getAll(environmentId), {
        params: { name, orderBy, orderType, limit, page },
      })
      .then((response) => {
        resolve(response.data.Data as JobOrder[]);
      });
  });

export const createJobOrder = (
  environmentId: string | number,
  jobOrder: JobOrder
): Promise<JobOrder> =>
  new Promise((resolve, reject) => {
    apiBase
      .post(endpoints.jobOrders.create(environmentId), jobOrder)
      .then((response) => {
        resolve(response.data.Data as JobOrder);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const updateJobOrder = (jobOrder: JobOrder): Promise<JobOrder> =>
  new Promise((resolve, reject) => {
    apiBase
      .put(endpoints.jobOrders.updateJobOrder(jobOrder.id), jobOrder)
      .then((response) => {
        resolve(response.data.Data as JobOrder);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const deleteJobOrder = (id: number | string): Promise<JobOrder> =>
  new Promise((resolve, reject) => {
    apiBase
      .delete(endpoints.jobOrders.delete(id))
      .then((response) => {
        resolve(response.data.Data as JobOrder);
      })
      .catch((error) => {
        reject(error);
      });
  });
