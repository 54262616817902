import React, { useCallback, useEffect } from 'react';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
//
import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function GuestButSimpleUserGuard({ children }: Props) {
  const router = useRouter();

  const { isAuthenticated, user } = useAuthContext();

  const check = useCallback(() => {
    if (isAuthenticated && user?.group !== 'end_user') {
      router.replace(paths.auth.downloadApp);
    }
  }, [isAuthenticated, router, user?.group]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
}
