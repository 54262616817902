import { http, delay, HttpResponse } from 'msw';
import { API_BASE_URL } from 'src/api/api-base';
import { endpoints } from 'src/config/endpoints';
import { CostCenters } from 'src/fixtures/cost-centers';
import CostCenter from 'src/models/cost-center';

export default [
  http.post(API_BASE_URL + endpoints.costCenters.create, async (req) => {
    await delay('real');

    const costCenter = (await req.request.json()) as CostCenter;
    costCenter.id = CostCenters.length + 1;
    CostCenters.push(costCenter);

    return HttpResponse.json(costCenter);
  }),
  http.put(API_BASE_URL + endpoints.costCenters.updateCostCenter(':costCenterId'), async (req) => {
    await delay('real');

    const costCenter = (await req.request.json()) as CostCenter;
    const index = CostCenters.findIndex((c) => c.id === Number(costCenter.id));
    CostCenters[index] = costCenter;

    return HttpResponse.json(costCenter);
  }),

  http.get(API_BASE_URL + endpoints.costCenters.getAll, async () => {
    await delay('real');
    return HttpResponse.json(CostCenters);
  }),

  http.delete(API_BASE_URL + endpoints.costCenters.delete(':costCenterId'), async (req) => {
    await delay('real');

    const { costCenterId } = req.params;
    const index = CostCenters.findIndex((costCenter) => costCenter.id === Number(costCenterId));
    CostCenters.splice(index, 1);

    return HttpResponse.json({});
  }),
];
