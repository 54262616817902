import { http, delay, HttpResponse } from 'msw';
import { API_BASE_URL } from 'src/api/api-base';
import { endpoints } from 'src/config/endpoints';
import { Categories } from 'src/fixtures/categories';
import Category from 'src/models/category';

export default [
  http.post(API_BASE_URL + endpoints.categories.create, async (req) => {
    await delay('real');

    const category = (await req.request.json()) as Category;
    category.id = Categories.length + 1;
    Categories.push(category);

    return HttpResponse.json(category);
  }),
  http.put(API_BASE_URL + endpoints.categories.updateCategory(':categoryId'), async (req) => {
    await delay('real');

    const category = (await req.request.json()) as Category;
    const index = Categories.findIndex((c) => c.id === Number(category.id));
    Categories[index] = category;

    return HttpResponse.json(category);
  }),
  http.get(API_BASE_URL + endpoints.categories.getAll, async () => {
    await delay('real');
    return HttpResponse.json(Categories);
  }),
  http.delete(API_BASE_URL + endpoints.categories.delete(':categoryId'), async (req) => {
    await delay('real');

    const { categoryId } = req.params;
    const index = Categories.findIndex((category) => category.id === Number(categoryId));
    Categories.splice(index, 1);

    return HttpResponse.json({});
  }),
];
