const ROOTS = {
  DASHBOARD: '',
};

export const paths = {
  website: {
    pricing: 'https://fees.world/fees-pricing',
  },
  // AUTH
  auth: {
    login: `/login`,
    verify: `/verify`,
    register: `/register`,
    newPassword: `/new-password`,
    forgotPassword: `/forgot-password`,
    downloadApp: `/download-app`,
  },
  // DASHBOARD
  dashboard: {
    root: `${ROOTS.DASHBOARD}/`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
    },
    users: {
      root: `${ROOTS.DASHBOARD}/users`,
      new: `${ROOTS.DASHBOARD}/users/new`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/users/${id}/edit`,
      reports: (id: string) => `${ROOTS.DASHBOARD}/users/${id}/reports`,
    },
    report: {
      root: `${ROOTS.DASHBOARD}/report`,
      new: `${ROOTS.DASHBOARD}/report/new`,
      list: `${ROOTS.DASHBOARD}/report/list`,
      cards: `${ROOTS.DASHBOARD}/report/cards`,
      profile: `${ROOTS.DASHBOARD}/report/profile`,
      account: `${ROOTS.DASHBOARD}/report/account`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/report/${id}/edit`,
      details: (id: string) => `${ROOTS.DASHBOARD}/report/${id}/view`,
    },
    environments: {
      root: `${ROOTS.DASHBOARD}/environments`,
      manage: `${ROOTS.DASHBOARD}/environments/manage`,
      new: `${ROOTS.DASHBOARD}/environments/new`,
      list: `${ROOTS.DASHBOARD}/environments/list`,
      cards: `${ROOTS.DASHBOARD}/environments/cards`,
      profile: `${ROOTS.DASHBOARD}/environments/profile`,
      account: `${ROOTS.DASHBOARD}/environments/account`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/environments/${id}/edit`,
      details: (id: string) => `${ROOTS.DASHBOARD}/environments/${id}/view`,
    },
    categories: {
      root: `${ROOTS.DASHBOARD}/categories`,
      new: `${ROOTS.DASHBOARD}/categories/new`,
    },
    tags: {
      root: `${ROOTS.DASHBOARD}/tags`,
      new: `${ROOTS.DASHBOARD}/tags/new`,
    },
    jobOrders: {
      root: `${ROOTS.DASHBOARD}/job-orders`,
      new: `${ROOTS.DASHBOARD}/job-orders/new`,
    },
    clients: {
      root: `${ROOTS.DASHBOARD}/clients`,
      new: `${ROOTS.DASHBOARD}/clients/new`,
    },
    costCenters: {
      root: `${ROOTS.DASHBOARD}/cost-centers`,
      new: `${ROOTS.DASHBOARD}/cost-centers/new`,
    },
    profile: {
      root: `${ROOTS.DASHBOARD}/profile`,
      plan: `${ROOTS.DASHBOARD}/plan`,
    }
  },
  settings: {
    root: '/settings',
  },
};
