import React, { lazy, Suspense } from 'react';
import DashboardLayout from 'src/layouts/dashboard';
import { AuthGuard, SimpleUserGuard } from 'src/auth/guard';
import { paths } from 'src/routes/paths';
import { SplashScreen } from 'src/components/loading-screen';
import { Outlet } from 'react-router-dom';

export const Dashboard = lazy(() => import('src/pages/dashboard'));
const Reports = lazy(() => import('src/pages/reports'));
const Environments = lazy(() => import('src/pages/environments'));
const Users = lazy(() => import('src/pages/users'));
// const UserNew = lazy(() => import('src/pages/users/user-new'));
const UserEdit = lazy(() => import('src/pages/users/user-edit'));

const app = {
  path: '',
  element: (
    <AuthGuard>
      <SimpleUserGuard>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </SimpleUserGuard>
    </AuthGuard>
  ),
  children: [
    {
      path: paths.dashboard.report.root,
      element: (
        <DashboardLayout>
          <Reports />
        </DashboardLayout>
      ),
    },
    {
      path: paths.dashboard.environments.root,
      element: (
        <DashboardLayout>
          <Environments />
        </DashboardLayout>
      ),
    },
    {
      path: paths.dashboard.users.root,
      element: (
        <DashboardLayout>
          <Users />
        </DashboardLayout>
      ),
    },
    /* {
    path: paths.dashboard.users.new,
    element: (
      <AuthGuard>
        <DashboardLayout>
          <UserNew />
        </DashboardLayout>
      </AuthGuard>
    ),
  }, */
    {
      path: paths.dashboard.users.edit(':id'),
      element: (
        <DashboardLayout>
          <UserEdit />
        </DashboardLayout>
      ),
    },
    {
      path: paths.dashboard.users.reports(':id'),
      element: (
        <DashboardLayout>
          <UserEdit initialTab="report" />
        </DashboardLayout>
      ),
    },
  ],
};

export const appRoutes = [
  {
    path: '',
    children: [app],
  },
];
