import CurrencyModel from 'src/models/currency';

export const Currency: CurrencyModel = {
  id: 0,
  currency: 'Euro',
  code: 'EUR',
  symbol: '€',
};

export const Currencies: CurrencyModel[] = [
  {
    id: 1,
    currency: 'Euro',
    code: 'EUR',
    symbol: '€',
  },
  {
    id: 2,
    currency: 'Dollar',
    code: 'USD',
    symbol: '$',
  },
  {
    id: 3,
    currency: 'Pound',
    code: 'GBP',
    symbol: '£',
  },
  {
    id: 4,
    currency: 'Franc',
    code: 'CHF',
    symbol: 'Fr.',
  },
  {
    id: 5,
    currency: 'Dollar',
    code: 'CAD',
    symbol: '$',
  },
  {
    id: 6,
    currency: 'Dollar',
    code: 'AUD',
    symbol: '$',
  },
  {
    id: 7,
    currency: 'Yuan Renminbi',
    code: 'CNY',
    symbol: '¥',
  },
  {
    id: 8,
    currency: 'Riyal',
    code: 'SAR',
    symbol: 'Sr',
  },
  {
    id: 9,
    currency: 'Dirham',
    code: 'AED',
    symbol: 'Dh',
  },
  {
    id: 10,
    currency: 'Yen',
    code: 'JPY',
    symbol: '円',
  },
];
