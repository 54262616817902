import React, { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
import { ICONS } from 'src/utils/icons';
import { useSessionContext } from 'src/contexts/session-context/hooks';
import { TranslatorFunction } from 'src/locales/use-locales';
import Label from 'src/components/label';
import { useGetAllReports } from 'src/hooks/use-api';

// ----------------------------------------------------------------------

const FULL_MENU = (reportsLabel: string, t: TranslatorFunction) => [
  // OVERVIEW
  // ----------------------------------------------------------------------
  {
    subheader: t('navmenu.overview'),
    items: [
      {
        title: t('navmenu.dashboard'),
        path: paths.dashboard.root,
        icon: ICONS.dashboard,
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: t('navmenu.management'),
    items: [
      {
        title: t('navmenu.reports'),
        path: paths.dashboard.report.root,
        icon: ICONS.reports,
        info:
          !reportsLabel || reportsLabel !== '0' ? (
            <Label color="primary">{reportsLabel}</Label>
          ) : undefined,
      },
      {
        title: t('navmenu.users'),
        path: paths.dashboard.users.root,
        icon: ICONS.users,
      },
      {
        title: t('navmenu.settings'),
        path: '#',
        icon: ICONS.settings,
        children: [
          {
            title: t('navmenu.categories'),
            path: paths.dashboard.categories.root,
          },
          {
            title: t('navmenu.job_orders'),
            path: paths.dashboard.jobOrders.root,
          },
          {
            title: t('navmenu.clients'),
            path: paths.dashboard.clients.root,
          },
          {
            title: t('navmenu.cost_centers'),
            path: paths.dashboard.costCenters.root,
          },
          {
            title: t('navmenu.tags'),
            path: paths.dashboard.tags.root,
          },
          {
            title: t('navmenu.environments-management'),
            path: paths.dashboard.environments.manage,
          },
        ],
      },
    ],
  },
];

const SIMPLIFIED_MENU = (t: TranslatorFunction) => [
  {
    subheader: t('navmenu.management'),
    items: [
      {
        title: t('navmenu.settings'),
        path: '#',
        icon: ICONS.settings,
        children: [
          {
            title: t('navmenu.environments-management'),
            path: paths.dashboard.environments.manage,
          },
        ],
      },
    ],
  },
];

export function useNavData() {
  const { t } = useLocales();
  const { environment } = useSessionContext();
  const { data: reports } = useGetAllReports(
    {
      environmentId: environment?.id,
    },
    { enabled: !!environment }
  );

  const pendingReportsCount = useMemo(() => {
    if (!reports) return '0';
    const { length } = reports.filter((report) => report.status === 0);
    return length > 99 ? '99+' : `${length}`;
  }, [reports]);

  return useMemo(
    () => (environment ? FULL_MENU(pendingReportsCount, t) : SIMPLIFIED_MENU(t)),
    [environment, pendingReportsCount, t]
  );
}
