import { Navigate, useRoutes } from 'react-router-dom';
import { AuthGuard, SimpleUserGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';
import { settingsRoutes } from 'src/routes/sections/settings';
import { profileRoutes } from 'src/routes/sections/profile';
import { extraRoutes } from './extra';
import { authRoutes } from './auth';
import { appRoutes, Dashboard } from './app';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard>
          <SimpleUserGuard>
            <DashboardLayout>
              <Dashboard />
            </DashboardLayout>
          </SimpleUserGuard>
        </AuthGuard>
      ),
    },
    ...authRoutes,
    ...extraRoutes,
    ...appRoutes,
    ...settingsRoutes,
    ...profileRoutes,
    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
