export const getSessionKey = (key: string) => localStorage.getItem(key) ?? '';

export const setSessionKey = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const removeSessionKey = (key: string) => {
  localStorage.removeItem(key);
};

export const clearSession = () => {
  localStorage.clear();
};
