import { useContext } from 'react';
//
import { SessionContext } from '../session-context';

// ----------------------------------------------------------------------

export const useSessionContext = () => {
  const context = useContext(SessionContext);

  if (!context) throw new Error('useSessionContext context must be use inside SessionProvider');

  return context;
};
