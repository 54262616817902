import { Me } from 'src/models/user';
import apiBase from 'src/api/api-base';
import { endpoints } from 'src/config/endpoints';

export const me = (): Promise<Me> =>
  new Promise((resolve, reject) => {
    apiBase.get(endpoints.auth.me).then((response) => {
      resolve(response.data.Data as Me);
    });
  });

export const updateProfile = (user: Me): Promise<Me> =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('avatar', user.avatar);

    Object.keys(user).forEach((key) => {
      if (key !== 'avatar') {
        formData.append(key, `${user[key as keyof Me]}`);
      }
    });

    apiBase
      .put(endpoints.profile.updateProfile, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data.Data as Me);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const changeProfilePassword = ({
  password,
  newPassword,
}: {
  password: string;
  newPassword: string;
}): Promise<Me> =>
  new Promise((resolve, reject) => {
    apiBase
      .put(endpoints.profile.changePassword, {
        password,
        newPassword,
      })
      .then((response) => {
        resolve(response.data.Data as Me);
      })
      .catch((error) => {
        reject(error);
      });
  });
