import React, { useMemo, useState } from 'react';
import { SessionContext } from 'src/contexts/session-context/session-context';
import { useGetCurrency, useGetEnvironment } from 'src/hooks/use-api';
import { getSessionKey, removeSessionKey, setSessionKey } from 'src/utils/session-utils';
import { useAuthContext } from 'src/auth/hooks';

type SessionProviderProps = PropsWithChildren;

export const SessionProvider: React.FC<SessionProviderProps> = ({ children }) => {
  const { isAuthenticated } = useAuthContext();

  return isAuthenticated ? (
    <AuthenticatedSession>{children}</AuthenticatedSession>
  ) : (
    <UnauthenticatedSession>{children}</UnauthenticatedSession>
  );
};

const UnauthenticatedSession: React.FC<SessionProviderProps> = ({ children }) => <>{children}</>;

const AuthenticatedSession: React.FC<SessionProviderProps> = ({ children }) => {
  const [environmentId, setEnvironment] = useState<number>(Number(getSessionKey('environmentId')));

  const { data: environment, isLoading: isLoadingEnvironment } = useGetEnvironment(environmentId, {
    enabled: !!environmentId && environmentId > 0,
  });

  const { data: currency } = useGetCurrency(environment?.currencyId, {
    enabled: !!environment,
  });

  const value = useMemo(
    () => ({
      environment,
      currency,
      isLoadingEnvironment: isLoadingEnvironment || environmentId === 0,
      setEnvironment: (id: number) => {
        setEnvironment(id);
        setSessionKey('environmentId', `${id}`);
      },
      reset: () => {
        setEnvironment(0);
        removeSessionKey('environmentId');
      },
    }),
    [currency, environment, isLoadingEnvironment, environmentId]
  );

  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>;
};
