import ChartExpenseByYear from 'src/models/chart-expense-by-year';
import ChartExpenseByCategory from 'src/models/chart-expense-by-category';
import { endpoints } from 'src/config/endpoints';
import apiBase from './api-base';

export type ChartExpenseByYearResponse = { year: YYYY, data: ChartExpenseByYear[] }[];

export const getChartExpensesByYear = (environmentId: string | number): Promise<ChartExpenseByYearResponse> =>
  new Promise((resolve, reject) => {
    apiBase
      .get(endpoints.dashboard.chartExpenses(environmentId), { params: { groupby: 'year' } })
      .then((response) => {
        resolve(response.data.Data as ChartExpenseByYearResponse);
      });
  });

export const getChartExpensesByCategory = (environmentId: string | number, year?: number | string): Promise<ChartExpenseByCategory[]> =>
  new Promise((resolve, reject) => {
    apiBase
      .get(endpoints.dashboard.chartExpenses(environmentId), { params: { groupby: 'category', year } })
      .then((response) => {
        resolve(response.data.Data as ChartExpenseByCategory[]);
      });
  });
