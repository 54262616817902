Object.defineProperty(String.prototype, 'ucfirst', {
  value() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false,
});

Object.defineProperty(String.prototype, 'capitalize', {
  value() {
    const words = this.split(' ');
    const capitalizedWords = words.map(
      (word: string) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(' ');
  },
  enumerable: false,
});

Object.defineProperty(String.prototype, 'format', {
  value(...args: (string | number)[]) {
    return this.replace(/{(\d+)}/g, (match: string, index: number) =>
      typeof args[index] !== 'undefined' ? `${args[index]}` : match
    );
  },
  enumerable: false,
});

Object.defineProperty(String.prototype, 'truncate', {
  value(length: number) {
    return this.length > length ? `${this.slice(0, length)}...` : this;
  },
  enumerable: false,
});

Object.defineProperty(String.prototype, 'isNullOrEmpty', {
  value: function isNullOrEmpty() {
    return this === null || this.trim() === "" || this === undefined;
  },
  enumerable: false
});
