import { http, delay, HttpResponse } from 'msw';
import { API_BASE_URL } from 'src/api/api-base';
import { endpoints } from 'src/config/endpoints';
import {
  ChartExpensesByCategory,
  ChartExpensesByYear2019,
  ChartExpensesByYear2020,
} from 'src/fixtures/chart-expenses';

export default [
  http.get(API_BASE_URL + endpoints.dashboard.chartExpenses, async ({ request }) => {
    const url = new URL(request.url);
    const groupby = url.searchParams.get('groupby');

    await delay('real');
    return HttpResponse.json(
      groupby === 'year'
        ? [
            {
              year: '2019',
              data: ChartExpensesByYear2019,
            },
            {
              year: '2020',
              data: ChartExpensesByYear2020,
            },
          ]
        : ChartExpensesByCategory
    );
  }),
];
