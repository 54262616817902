import React, { lazy } from 'react';
import DashboardLayout from 'src/layouts/dashboard';
import { AuthGuard, SimpleUserGuard } from 'src/auth/guard';
import { paths } from 'src/routes/paths';
import { Outlet } from 'react-router-dom';

export const Categories = lazy(() => import('src/pages/categories'));
export const JobOrders = lazy(() => import('src/pages/job-orders'));
export const Clients = lazy(() => import('src/pages/clients'));
export const CostCenters = lazy(() => import('src/pages/cost-centers'));
export const Tags = lazy(() => import('src/pages/tags'));
export const EnvironmentsManagement = lazy(
  () => import('src/pages/environments/environments-management')
);

const settings = {
  path: '',
  element: (
    <AuthGuard>
      <SimpleUserGuard>
        <Outlet />
      </SimpleUserGuard>
    </AuthGuard>
  ),
  children: [
    {
      path: paths.dashboard.categories.root,
      element: (
        <DashboardLayout>
          <Categories />
        </DashboardLayout>
      ),
    },
    {
      path: paths.dashboard.jobOrders.root,
      element: (
        <DashboardLayout>
          <JobOrders />
        </DashboardLayout>
      ),
    },
    {
      path: paths.dashboard.clients.root,
      element: (
        <DashboardLayout>
          <Clients />
        </DashboardLayout>
      ),
    },
    {
      path: paths.dashboard.costCenters.root,
      element: (
        <DashboardLayout>
          <CostCenters />
        </DashboardLayout>
      ),
    },
    {
      path: paths.dashboard.tags.root,
      element: (
        <DashboardLayout>
          <Tags />
        </DashboardLayout>
      ),
    },
    {
      path: paths.dashboard.environments.manage,
      element: (
        <DashboardLayout>
          <EnvironmentsManagement />
        </DashboardLayout>
      ),
    },
  ],
};

export const settingsRoutes = [
  {
    path: '',
    children: [settings],
  },
];
