import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestButSimpleUserGuard, GuestGuard } from 'src/auth/guard';
// components
import { SplashScreen } from 'src/components/loading-screen';
import AuthModernLayout from 'src/layouts/auth/modern';
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';

// ----------------------------------------------------------------------

const LoginPage = lazy(() => import('src/pages/login'));
const RegisterPage = lazy(() => import('src/pages/register'));
const RegisterAppPage = lazy(() => import('src/pages/register-app'));
const VerifyPage = lazy(() => import('src/pages/verify'));
const ConfirmInvitationPage = lazy(() => import('src/pages/confirm-invitation'));
const ActivationPage = lazy(() => import('src/pages/activation'));
const ForgotPasswordPage = lazy(() => import('src/pages/forgot-password'));
const ResetPassword = lazy(() => import('src/pages/reset-password'));
const DownloadApp = lazy(() => import('src/pages/download-app'));

// ----------------------------------------------------------------------

const auth = {
  path: '',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthModernLayout>
          <LoginPage />
        </AuthModernLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthModernLayout>
          <RegisterPage />
        </AuthModernLayout>
      ),
    }, {
      path: 'register_app',
      element: (
        <AuthModernLayout pro={false}>
          <RegisterAppPage />
        </AuthModernLayout>
      ),
    },
    {
      path: 'verify',
      element: (
        <AuthModernCompactLayout>
          <VerifyPage />
        </AuthModernCompactLayout>
      ),
    },
    {
      path: 'invitation/:code',
      element: (
        <AuthModernCompactLayout>
          <ConfirmInvitationPage />
        </AuthModernCompactLayout>
      ),
    },
    {
      path: 'activation/:code',
      element: (
        <AuthModernCompactLayout>
          <ActivationPage />
        </AuthModernCompactLayout>
      ),
    },
    {
      path: 'forgot-password',
      element: (
        <AuthModernCompactLayout>
          <ForgotPasswordPage />
        </AuthModernCompactLayout>
      ),
    },
    {
      path: 'reset-password/:code',
      element: (
        <AuthModernCompactLayout>
          <ResetPassword />
        </AuthModernCompactLayout>
      ),
    },
  ],
};

const downloadApp = {
  path: '',
  element: (
    <GuestButSimpleUserGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestButSimpleUserGuard>
  ),
  children: [
    {
      path: 'download-app',
      element: (
        <AuthModernLayout>
          <DownloadApp />
        </AuthModernLayout>
      ),
    },
  ],
};

export const authRoutes = [
  {
    path: '',
    children: [auth, downloadApp],
  },
];
