import { http, delay, HttpResponse } from 'msw';
import { API_BASE_URL } from 'src/api/api-base';
import { endpoints } from 'src/config/endpoints';
import { Tags } from 'src/fixtures/tags';
import Tag from 'src/models/tag';

export default [
  http.get(API_BASE_URL + endpoints.tags.getAll, async () => {
    await delay('real');
    return HttpResponse.json(Tags);
  }),
  http.post(API_BASE_URL + endpoints.tags.create, async (req) => {
    await delay('real');

    const tag = (await req.request.json()) as Tag;
    tag.id = Tags.length + 1;
    tag.color = tag.color.includes('#') ? tag.color.slice(1) : tag.color;
    tag.hasdate = tag.hasdate ? 1 : 0;
    tag.documents = tag.documents ? 1 : 0;
    Tags.push(tag);

    return HttpResponse.json(tag);
  }),
  http.put(API_BASE_URL + endpoints.tags.updateTag(':tagId'), async (req) => {
    await delay('real');

    const tag = (await req.request.json()) as Tag;

    tag.color = tag.color.includes('#') ? tag.color.slice(1) : tag.color;
    tag.hasdate = tag.hasdate ? 1 : 0;
    tag.documents = tag.documents ? 1 : 0;
    const index = Tags.findIndex((t) => t.id === Number(tag.id));
    Tags[index] = tag;

    return HttpResponse.json(tag);
  }),
  http.delete(API_BASE_URL + endpoints.tags.delete(':tagId'), async (req) => {
    await delay('real');

    const { tagId } = req.params;
    const index = Tags.findIndex((tag) => tag.id === Number(tagId));
    Tags.splice(index, 1);

    return HttpResponse.json({});
  }),
];
