import Report from 'src/models/report';

export const Reports: Report[] = [
  {
    n_fees: 1,
    n_accepted: 0,
    n_refused: 0,
    totalValue: 50,
    first_name: 'Thomas',
    last_name: 'Cocchiara',
    sendtoemail: 'thomas@fees.world',
    avatar: 'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50',
    id: 1,
    reportname: 'aws-buono-50\u20ac',
    iduser: 18044,
    warranty: null,
    deduction: null,
    expensesreport: null,
    merchant: '',
    fromdate: '2023-11-07',
    todate: '2023-11-14',
    categoryid: '1265,1273,1019,1015,1113,1267,1270,1268,1266,1112,1115,1472,1271,2568',
    tagsid: '',
    headmsg: 'Di seguito il riepilogo delle spese.',
    sendtoname: 'thomas@fees.world',
    dateRequest: '2023-11-14 10:26:59',
    idenvironment: '6',
    status: 0,
    errormsg: null,
    linkxls: 'report/18044/1699957619.xlsx',
    linkzip:
      'https://fees-world.s3.eu-central-1.amazonaws.com/report/18044/1699957619.zip?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2BVPDEPN7TSL5E7S%2F20231115%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20231115T224252Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1200&X-Amz-Signature=58044373d6443ecb013231dd994b5df2870dcb6c60a8d8ca9503aba16f6531a6',
    nfees: 1,
    deleted: false,
    notes: null,
  },
  {
    n_fees: 47,
    n_accepted: 0,
    n_refused: 0,
    totalValue: 716.4584399280695,
    first_name: 'Paolo',
    last_name: 'Pavesi',
    sendtoemail: 'paolopavesi90@gmail.com',
    avatar: 'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50',
    id: 2,
    reportname: '21-',
    iduser: 6,
    warranty: null,
    deduction: null,
    expensesreport: null,
    merchant: '',
    fromdate: '2023-06-01',
    todate: '2023-11-30',
    categoryid: '1265,1273,1019,1015,1113,1267,1270,1268,1266,1112,1115,1271',
    tagsid: '',
    headmsg: 'Di seguito il riepilogo delle spese.',
    sendtoname: 'paolopavesi90@gmail.com',
    dateRequest: '2023-11-02 17:02:44',
    idenvironment: '6',
    status: 1,
    errormsg: null,
    linkxls: 'report/6/1698944564.xlsx',
    linkzip:
      'https://fees-world.s3.eu-central-1.amazonaws.com/report/6/1698944564.zip?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2BVPDEPN7TSL5E7S%2F20231115%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20231115T224252Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1200&X-Amz-Signature=3d5febe66bbb1636079792a0456967a494061f7e85a8835f1c3dd321f6243a6b',
    nfees: 47,
    deleted: false,
    notes: null,
  },
  {
    n_fees: 2,
    n_accepted: 0,
    n_refused: 0,
    totalValue: 16,
    first_name: 'Fulvio',
    last_name: 'Lazzari',
    sendtoemail: 'fulvio.lazzari@fees.world',
    avatar: 'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50',
    id: 3,
    reportname: 'trasferta 21 settembre-Cremona',
    iduser: 14353,
    warranty: null,
    deduction: null,
    expensesreport: null,
    merchant: '',
    fromdate: '2023-08-17',
    todate: '2023-10-17',
    categoryid: '1271',
    tagsid: '1039,1003',
    headmsg: 'Di seguito il riepilogo delle spese.',
    sendtoname: 'fulvio.lazzari@fees.world',
    dateRequest: '2023-10-17 10:16:23',
    idenvironment: '6',
    status: 2,
    errormsg: null,
    linkxls: 'report/14353/1697537783.xlsx',
    linkzip:
      'https://fees-world.s3.eu-central-1.amazonaws.com/report/14353/1697537783.zip?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2BVPDEPN7TSL5E7S%2F20231115%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20231115T224252Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1200&X-Amz-Signature=870c3ac4483789929b70ce022e5d92e78cbbac1f8833ddac330ee0e5ef7fb9eb',
    nfees: 2,
    deleted: false,
    notes: null,
  },
];
