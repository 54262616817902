import apiBase from 'src/api/api-base';
import { endpoints } from 'src/config/endpoints';
import Environment from 'src/models/environment';

export const getEnvironment = (id: number | string): Promise<Environment> =>
  new Promise((resolve, reject) => {
    apiBase.get(endpoints.environments.getEnvironment(id)).then((response) => {
      resolve(response.data.Data as Environment);
    });
  });

export const getEnvironmentsCount = (name?: string): Promise<number> =>
  new Promise((resolve, reject) => {
    apiBase
      .get(endpoints.environments.count, {
        params: { name },
      })
      .then((response) => {
        resolve(parseInt(response.data.Data, 10) || 0);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getAllEnvironments = (
  name?: string,
  orderBy?: string,
  orderType?: string,
  limit?: number,
  page?: number
): Promise<Environment[]> =>
  new Promise((resolve, reject) => {
    apiBase
      .get(endpoints.environments.getAll, {
        params: { limit, page, name, orderBy, orderType },
      })
      .then((response) => {
        resolve(response.data.Data as Environment[]);
      });
  });

export const createEnvironment = (environment: Environment): Promise<Environment> =>
  new Promise((resolve, reject) => {
    apiBase.post(endpoints.environments.create, environment).then((response) => {
      resolve(response.data.Data as Environment);
    });
  });

export const updateEnvironment = (environment: Environment): Promise<Environment> =>
  new Promise((resolve, reject) => {
    apiBase
      .put(endpoints.environments.updateEnvironment(environment.id), environment)
      .then((response) => {
        resolve(response.data.Data as Environment);
      });
  });

export const updateEnvironmentLogo = (id: number | string, logo: File): Promise<Environment> =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('logo', logo);
    apiBase.put(endpoints.environments.updateEnvironmentLogo(id), formData).then((response) => {
      resolve(response.data.Data as Environment);
    });
  });

export const deleteEnvironment = (id: number | string): Promise<Environment> =>
  new Promise((resolve, reject) => {
    apiBase.delete(endpoints.environments.delete(id)).then((response) => {
      resolve(response.data.Data as Environment);
    });
  });

export const changeAdmin = (id: number | string, id_admin: number | string): Promise<Environment> =>
  new Promise((resolve, reject) => {
    apiBase.put(endpoints.environments.changeAdmin(id), { id_admin }).then((response) => {
      resolve(response.data.Data as Environment);
    });
  });

export const assignLicenses = (
  id: number | string,
  n_licenze: number | string
): Promise<Environment> =>
  new Promise((resolve, reject) => {
    apiBase.put(endpoints.environments.assignLicenses(id), { n_licenze }).then((response) => {
      resolve(response.data.Data as Environment);
    });
  });
