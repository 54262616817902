import Category from 'src/models/category';

export const Categories: Category[] = [
  {
    id: 0,
    code: 1015,
    standard: 0,
    name: 'Cibo & Ristoranti',
    status: 1,
    userID: null,
    warranty: 0,
    deduction: 0,
    expensesReport: 0,
    environmentID: 6,
    documents: 0,
    rimborso_km: 0.00,
    max_price: 0.00,
    categoryNotes: 'Spese per pranzi, cene e caff\u00e8',
    totalValue: 4617.85,
    n_fees: 248,
  },
  {
    id: 1,
    code: 1019,
    standard: 0,
    name: 'Carburante',
    status: 1,
    userID: null,
    warranty: 0,
    deduction: 0,
    expensesReport: 0,
    environmentID: 6,
    documents: 0,
    rimborso_km: 0.00,
    max_price: 0.00,
    categoryNotes: '',
    totalValue: 1926.94,
    n_fees: 54,
  },
  {
    id: 2,
    code: 1036,
    standard: 0,
    name: 'Documenti',
    status: 1,
    userID: null,
    warranty: 0,
    deduction: 0,
    expensesReport: 0,
    environmentID: 6,
    documents: 1,
    rimborso_km: 0.00,
    max_price: 0.00,
    categoryNotes: '',
    totalValue: 0.00,
    n_fees: 16,
  },
  {
    id: 3,
    code: 1112,
    standard: 0,
    name: 'Software',
    status: 1,
    userID: null,
    warranty: 0,
    deduction: 0,
    expensesReport: 0,
    environmentID: 6,
    documents: 0,
    rimborso_km: 0.00,
    max_price: 1000.00,
    categoryNotes: '',
    totalValue: 5123.90,
    n_fees: 13,
  },
  {
    id: 4,
    code: 1113,
    standard: 0,
    name: 'Hardware',
    status: 1,
    userID: null,
    warranty: 0,
    deduction: 0,
    expensesReport: 0,
    environmentID: 6,
    documents: 0,
    rimborso_km: 0.00,
    max_price: 0.00,
    categoryNotes: '',
    totalValue: 2066.38,
    n_fees: 8,
  },
  {
    id: 5,
    code: 1115,
    standard: 0,
    name: 'Spese Varie',
    status: 1,
    userID: null,
    warranty: 0,
    deduction: 0,
    expensesReport: 0,
    environmentID: 6,
    documents: 0,
    rimborso_km: 0.00,
    max_price: 0.00,
    categoryNotes: '',
    totalValue: 254.45,
    n_fees: 12,
  },
  {
    id: 6,
    code: 1265,
    standard: 0,
    name: 'Autostrade e Pedaggi',
    status: 1,
    userID: null,
    warranty: 0,
    deduction: 0,
    expensesReport: 0,
    environmentID: 6,
    documents: 0,
    rimborso_km: 0.00,
    max_price: 0.00,
    categoryNotes: '',
    totalValue: 162.00,
    n_fees: 23,
  },
  {
    id: 7,
    code: 1266,
    standard: 0,
    name: 'Rimborso Km',
    status: 1,
    userID: null,
    warranty: 0,
    deduction: 0,
    expensesReport: 0,
    environmentID: 6,
    documents: 0,
    rimborso_km: 0.50,
    max_price: 0.00,
    categoryNotes: 'Rimborso chilometrico per le trasferte',
    totalValue: 67.80,
    n_fees: 1,
  },
  {
    id: 8,
    code: 1267,
    standard: 0,
    name: 'Hotel',
    status: 1,
    userID: null,
    warranty: 0,
    deduction: 0,
    expensesReport: 0,
    environmentID: 6,
    documents: 0,
    rimborso_km: 0.00,
    max_price: 0.00,
    categoryNotes: '',
    totalValue: 0.00,
    n_fees: 0,
  },
  {
    id: 214619,
    code: 1268,
    standard: 0,
    name: 'Parcheggio',
    status: 1,
    userID: null,
    warranty: 0,
    deduction: 0,
    expensesReport: 0,
    environmentID: 6,
    documents: 0,
    rimborso_km: 0.00,
    max_price: 0.00,
    categoryNotes: '',
    totalValue: 39.40,
    n_fees: 2,
  },
  {
    id: 9,
    code: 1270,
    standard: 0,
    name: 'Noleggio Veicolo',
    status: 1,
    userID: null,
    warranty: 0,
    deduction: 0,
    expensesReport: 0,
    environmentID: 6,
    documents: 0,
    rimborso_km: 0.00,
    max_price: 0.00,
    categoryNotes: '',
    totalValue: 0.00,
    n_fees: 0,
  },
  {
    id: 10,
    code: 1271,
    standard: 0,
    name: 'Trasporti Pubblici',
    status: 1,
    userID: null,
    warranty: 0,
    deduction: 0,
    expensesReport: 0,
    environmentID: 6,
    documents: 0,
    rimborso_km: 0.00,
    max_price: 0.00,
    categoryNotes: '',
    totalValue: 84.05,
    n_fees: 9,
  },
  {
    id: 11,
    code: 1273,
    standard: 0,
    name: 'Biglietti',
    status: 1,
    userID: null,
    warranty: 0,
    deduction: 0,
    expensesReport: 0,
    environmentID: 6,
    documents: 0,
    rimborso_km: 0.00,
    max_price: 0.00,
    categoryNotes: 'Biglietti del treno, Aereo',
    totalValue: 85.25,
    n_fees: 5,
  },
  {
    id: 12,
    code: 2501,
    standard: 0,
    name: 'Documenti & altro',
    status: 1,
    userID: null,
    warranty: 0,
    deduction: 0,
    expensesReport: 0,
    environmentID: 6,
    documents: 1,
    rimborso_km: 0.00,
    max_price: 0.00,
    categoryNotes: null,
    totalValue: 0.00,
    n_fees: 2,
  },
];
