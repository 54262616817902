import { endpoints } from 'src/config/endpoints';
import apiBase from 'src/api/api-base';
import Category from 'src/models/category';
import Tag from 'src/models/tag';

export const getCategoriesCount = (
  environmentId: string | number,
  name: string | undefined,
  documents: number | undefined
): Promise<number> =>
  new Promise((resolve, reject) => {
    apiBase
      .get(endpoints.categories.count(environmentId), {
        params: { name, documents },
      })
      .then((response) => {
        resolve(parseInt(response.data.Data, 10) || 0);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getAllCategories = (
  environmentId: number | string,
  name: string | undefined,
  orderBy: string | undefined,
  orderType: string | undefined,
  limit: number | undefined,
  page: number | undefined,
  documents: number | undefined
): Promise<Category[]> =>
  new Promise((resolve, reject) => {
    apiBase
      .get(endpoints.categories.getAll(environmentId), {
        params: { name, orderBy, orderType, limit, page, documents },
      })
      .then((response) => {
        resolve(response.data.Data as Category[]);
      });
  });

export const deleteCategory = (id: number): Promise<Category[]> =>
  new Promise((resolve, reject) => {
    apiBase.delete(endpoints.categories.delete(id)).then((response) => {
      resolve(response.data.Data as Category[]);
    });
  });

export const createCategory = (
  environmentId: number | string,
  category: Category
): Promise<Category> =>
  new Promise((resolve, reject) => {
    apiBase.post(endpoints.categories.create(environmentId), category).then((response) => {
      resolve(response.data.Data as Category);
    });
  });

export const updateCategory = (category: Category): Promise<Category[]> =>
  new Promise((resolve, reject) => {
    apiBase.put(endpoints.categories.updateCategory(category.id), category).then((response) => {
      resolve(response.data.Data as Category[]);
    });
  });

export const getCategoryTags = (categoryId: number | string): Promise<Tag[]> =>
  new Promise((resolve, reject) => {
    apiBase.get(endpoints.categories.getTags(categoryId)).then((response) => {
      resolve(response.data.Data as Tag[]);
    });
  });
