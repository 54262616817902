import User, { UserOverrides } from 'src/models/user';
import apiBase from 'src/api/api-base';
import { endpoints } from 'src/config/endpoints';
import UsersList from 'src/models/users-list';
import NewEditUser from 'src/models/new-edit-user';

export const getUsersCount = (
  environmentId: string | number,
  name: string | undefined,
  status: number | undefined
): Promise<number> =>
  new Promise((resolve, reject) => {
    apiBase
      .get(endpoints.users.count(environmentId), {
        params: { name, status },
      })
      .then((response) => {
        resolve(parseInt(response.data.Data, 10) || 0);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getUsers = (
  environmentId: string | number,
  name: string | undefined,
  status: number | undefined,
  orderBy: string | undefined,
  orderType: string | undefined,
  limit: number | undefined,
  page: number | undefined
): Promise<UsersList> =>
  new Promise((resolve, reject) => {
    apiBase.get(endpoints.users.getAll(environmentId), {
      params: { name, status, orderBy, orderType, limit, page },
    })
    .then((response) => {
      resolve(response.data.Data as UsersList);
    });
  });

export const getUser = (environmentId: number | string, id: number | string): Promise<User> =>
  new Promise((resolve, reject) => {
    apiBase.get(endpoints.users.getUser(environmentId, id)).then((response) => {
      resolve(response.data.Data as User);
    });
  });

export const addUser = (environmentId: string | number, user: NewEditUser): Promise<NewEditUser> =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('avatar', user.avatar);

    Object.keys(user).forEach((key) => {
      if (key !== 'avatar') {
        formData.append(key, `${user[key as keyof NewEditUser]}`);
      }
    });

    apiBase
      .post(endpoints.users.addUser(environmentId), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data.Data as NewEditUser);
      });
  });

export const inviteUser = ({
  environmentId,
  user,
}: {
  environmentId: string | number;
  user: { email: string };
}): Promise<NewEditUser> =>
  new Promise((resolve, reject) => {
    apiBase
      .post(endpoints.users.addUser(environmentId), user)
      .then((response) => {
        resolve(response.data.Data as NewEditUser);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const resendInvitation = ({
  environmentId,
  userId,
}: {
  environmentId: string | number;
  userId: number | string;
}): Promise<void> =>
  new Promise((resolve, reject) => {
    apiBase
      .post(endpoints.users.resendInvitation(environmentId, userId))
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

export const updateUser = (user: User): Promise<NewEditUser> =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('avatar', user.avatar);

    Object.keys(user).forEach((key) => {
      if (key !== 'avatar') {
        formData.append(key, `${user[key as keyof NewEditUser]}`);
      }
    });

    apiBase
      .put(endpoints.users.updateUser(user.id), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data.Data as NewEditUser);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const updateUserOverrides = (
  environmentId: number | string,
  user: UserOverrides
): Promise<UserOverrides> =>
  new Promise((resolve, reject) => {
    apiBase
      .put(endpoints.users.updateUserOverrides(environmentId, user.id), user)
      .then((response) => {
        resolve(response.data.Data as UserOverrides);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const removeUserOverrides = (
  environmentId: number | string,
  userId: number | string
): Promise<void> =>
  new Promise((resolve, reject) => {
    apiBase
      .delete(endpoints.users.updateUserOverrides(environmentId, userId))
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

export const disableUser = ({
  environmentId,
  userId,
}: {
  environmentId: number | string;
  userId: number | string;
}): Promise<void> =>
  new Promise((resolve, reject) => {
    apiBase
      .post(endpoints.users.disableUser(environmentId, userId))
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

export const enableUser = ({
  environmentId,
  userId,
}: {
  environmentId: number | string;
  userId: number | string;
}): Promise<void> =>
  new Promise((resolve, reject) => {
    apiBase
      .post(endpoints.users.enableUser(environmentId, userId))
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

export const deleteUser = ({
  environmentId,
  userId,
}: {
  environmentId: number | string;
  userId: number | string;
}): Promise<void> =>
  new Promise((resolve, reject) => {
    apiBase
      .delete(endpoints.users.deleteUser(environmentId, userId))
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

export const importFromExcel = ({
  environmentId,
  file,
}: {
  environmentId: number | string;
  file: File;
}): Promise<void> =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('import_file', file);

    apiBase
      .post(endpoints.users.importFromExcel(environmentId), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
