import { http, delay, HttpResponse } from 'msw';
import { API_BASE_URL } from 'src/api/api-base';
import { endpoints } from 'src/config/endpoints';
import { Environment, Environments } from 'src/fixtures/environments';
import { Users } from 'src/fixtures/users';
import EnvironmentModel from 'src/models/environment';

export default [
  http.get(API_BASE_URL + endpoints.environments.getEnvironment(':environmentId'), async () => {
    await delay('real');
    return HttpResponse.json(Environment);
  }),
  http.post(API_BASE_URL + endpoints.environments.create, async (req) => {
    await delay('real');
    const environment = (await req.request.json()) as EnvironmentModel;
    environment.id = Environments.length + 1;
    environment.active = true;
    environment.n_licenze_usate = 0;
    environment.n_licenze = 0;
    Environments.push(environment);
    return HttpResponse.json(environment);
  }),
  http.put(API_BASE_URL + endpoints.environments.updateEnvironment(':environmentId'), async (req) => {
    await delay('real');
    const environment = (await req.request.json()) as EnvironmentModel;
    const index = Environments.findIndex((e) => e.id === Number(environment.id));
    Environments[index] = { ...Environments[index], ...environment };
    return HttpResponse.json(environment);
  }),
  http.get(API_BASE_URL + endpoints.environments.getAll, async () => {
    await delay('real');
    return HttpResponse.json(Environments);
  }),
  http.delete(API_BASE_URL + endpoints.environments.delete(':environmentId'), async (req) => {
    await delay('real');
    const { environmentId } = req.params;
    const index = Environments.findIndex((environment) => environment.id === Number(environmentId));
    Environments.splice(index, 1);
    return HttpResponse.json({});
  }),
  http.put(API_BASE_URL + endpoints.environments.changeAdmin(':environmentId'), async (req) => {
    await delay('real');
    const { environmentId } = req.params;
    const { id_superadmin } = await req.request.json() as { id_superadmin: string };
    const index = Environments.findIndex((environment) => environment.id === Number(environmentId));
    Environments[index].id_superadmin = id_superadmin;

    const userIndex = Users.findIndex((user) => user.id === id_superadmin);

    Environments[index].superadmin_username = Users[userIndex].username;

    return HttpResponse.json(Environments[index]);
  }),
  http.put(API_BASE_URL + endpoints.environments.assignLicenses(':environmentId'), async (req) => {
    await delay('real');
    const { environmentId } = req.params;
    const { n_licenze } = await req.request.json() as { n_licenze: string };
    const index = Environments.findIndex((environment) => environment.id === Number(environmentId));
    Environments[index].n_licenze = Number(n_licenze);
    return HttpResponse.json(Environments[index]);
  })
];
