import UsersList from 'src/models/users-list';

export const Users: UsersList = [
  {
    n_da_controllare: '4',
    username: 'paolopavesi90@gmail.com',
    id: '6',
    avatar: 'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50',
    first_name: 'Paolo',
    last_name: 'Pavesi',
    email: 'paolopavesi90@gmail.com',
    last_app_user_access: '2023-11-27 10:04:59',
    active: 1,
    codice_fiscale: 'PVSPLA90E07D150H',
    vat_code: '',
    company: 'Fees Srl',
    phone: '3294093342',
    birthdate: '1899-12-30',
    active_bu: 1,
    invitation_status: 1,
    invitation_date: '2021-02-09 10:43:03',
    confirmation_date: '2021-02-09 10:47:17',
    invitation_code: 'oFdolArlIRwJqFQC1tIuPDK2iGwEl4RT',
  },
  {
    n_da_controllare: '1',
    username: 'matteo@fees.world',
    id: '11',
    avatar: 'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50',
    first_name: 'Matteo',
    last_name: 'Mazzolari',
    email: 'matteo@fees.world',
    last_app_user_access: '2023-11-28 16:55:31',
    active: 1,
    codice_fiscale: 'MZZMTT94B01E884U',
    vat_code: 'MZZMTT94B01E884U',
    company: 'Fees S.r.l.',
    phone: null,
    birthdate: '1994-02-01',
    active_bu: 1,
    invitation_status: 1,
    invitation_date: '2021-02-09 09:58:11',
    confirmation_date: '2021-02-09 10:07:11',
    invitation_code: 'gPQDB69fQeUXBg6Qm9d3Frjk1iHgfP5w',
  },
  {
    n_da_controllare: '0',
    username: 'paolo@fees.world',
    id: '117',
    avatar: 'guest.png',
    first_name: 'Paolo',
    last_name: 'Pavesi',
    email: 'paolo@fees.world',
    last_app_user_access: '2023-04-18 08:07:25',
    active: 1,
    codice_fiscale: '',
    vat_code: null,
    company: 'Fees Srl',
    phone: '',
    birthdate: '1899-12-30',
    active_bu: 0,
    invitation_status: 0,
    invitation_date: '2021-02-09 22:13:15',
    confirmation_date: '2021-02-09 22:14:10',
    invitation_code: 'XxGt1tq1IC28T5ZSAC6XNPylDtSHa4zF',
  },
  {
    n_da_controllare: '0',
    username: 'francesco@fees.world',
    id: '14342',
    avatar: 'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50',
    first_name: 'Francesco',
    last_name: 'Meli',
    email: 'francesco@fees.world',
    last_app_user_access: '2023-09-25 07:27:36',
    active: 1,
    codice_fiscale: null,
    vat_code: '',
    company: 'fees S.r.l.',
    phone: null,
    birthdate: '1899-11-29',
    active_bu: 1,
    invitation_status: 1,
    invitation_date: '2022-05-10 16:32:54',
    confirmation_date: '2022-05-11 10:25:41',
    invitation_code: 'uuj8bZtF7Ic3sh3tbPl5AZizvVF0HWIz',
  },
  {
    n_da_controllare: '1',
    username: 'fulvio.lazzari@fees.world',
    id: '14353',
    avatar: 'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50',
    first_name: 'Fulvio',
    last_name: 'Lazzari',
    email: 'fulvio.lazzari@fees.world',
    last_app_user_access: '2023-11-27 23:34:57',
    active: 1,
    codice_fiscale: '',
    vat_code: 'LZZFLV91H25F280Z',
    company: 'fees S.r.l.',
    phone: '',
    birthdate: '1991-06-25',
    active_bu: 1,
    invitation_status: 1,
    invitation_date: '2022-05-11 10:27:43',
    confirmation_date: '2022-05-13 15:26:13',
    invitation_code: 'OC1BclbyKBaF9mZcxWa04wrlZEsRLLWY',
  },
  {
    n_da_controllare: '0',
    username: 'valeriacarlino100@gmail.com',
    id: '14487',
    avatar: 'guest.png',
    first_name: 'Valeria',
    last_name: 'Carlino',
    email: 'valeriacarlino100@gmail.com',
    last_app_user_access: '2023-11-28 12:56:16',
    active: 1,
    codice_fiscale: null,
    vat_code: '',
    company: 'fees S.r.l.',
    phone: null,
    birthdate: '1999-12-18',
    active_bu: 1,
    invitation_status: 1,
    invitation_date: '2022-09-05 12:56:34',
    confirmation_date: '2022-09-05 12:57:28',
    invitation_code: '2TKbRKwITP3m55R26NfJWm98lYsRrvEV',
  },
  {
    n_da_controllare: '0',
    username: 'f.mazzolari@fees.world',
    id: '15466',
    avatar: 'guest.png',
    first_name: 'Fabrizio',
    last_name: 'Mazzolari',
    email: 'f.mazzolari@fees.world',
    last_app_user_access: '2022-10-16 12:28:56',
    active: 1,
    codice_fiscale: null,
    vat_code: '',
    company: 'fees S.r.l.',
    phone: null,
    birthdate: '0000-00-00',
    active_bu: 1,
    invitation_status: 1,
    invitation_date: '2022-09-21 17:07:21',
    confirmation_date: '2022-09-21 17:11:13',
    invitation_code: 'oM8Dp5rY4gUK2oaSdra1Dx8zYnlHx6jL',
  },
  {
    n_da_controllare: '2',
    username: 'thomas@fees.world',
    id: '18044',
    avatar: 'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50',
    first_name: 'Thomas',
    last_name: 'Cocchiara',
    email: 'thomas@fees.world',
    last_app_user_access: '2023-11-14 12:36:51',
    active: 1,
    codice_fiscale: null,
    vat_code: '',
    company: 'fees S.r.l.',
    phone: null,
    birthdate: '1991-07-01',
    active_bu: 1,
    invitation_status: 1,
    invitation_date: '2023-03-09 10:02:00',
    confirmation_date: '2023-03-09 10:03:02',
    invitation_code: '9qUwnos82HFvVXICgfl6mG17KRP4lRsk',
  },
];
