import { http, delay, HttpResponse } from 'msw';
import { API_BASE_URL } from 'src/api/api-base';
import { Users } from 'src/fixtures/users';
import { User } from 'src/fixtures/user';
import { endpoints } from 'src/config/endpoints';

export default [
  http.get(API_BASE_URL + endpoints.users.getAll, async () => {
    await delay('real');
    return HttpResponse.json(Users);
  }),
  http.get(API_BASE_URL + endpoints.users.getUser(':environmentId', ':id'), async () => {
    await delay('real');
    return HttpResponse.json(User);
  }),
];
