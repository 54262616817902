import Subscription from 'src/models/subscription';
import apiBase from 'src/api/api-base';
import { endpoints } from 'src/config/endpoints';

export const getSubscriptions = (): Promise<Subscription[]> =>
  new Promise((resolve, reject) => {
    apiBase
      .get(endpoints.subscriptions.getSubscriptions)
      .then((response) => {
        resolve(response.data.Data as Subscription[]);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getCheckoutUri = (subscriptionId: string, redirectUrl: string): Promise<string> =>
  new Promise((resolve, reject) => {
    apiBase
      .get(endpoints.subscriptions.getCheckoutUri(subscriptionId), { params: { redirectUrl } })
      .then((response) => {
        resolve(response.data.Data as string);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getChangePaymentMethodUri = (subscriptionId: string, redirectUrl: string): Promise<string> =>
  new Promise((resolve, reject) => {
    apiBase
      .get(endpoints.subscriptions.getChangePaymentMethodUri(subscriptionId), { params: { redirectUrl } })
      .then((response) => {
        resolve(response.data.Data as string);
      })
      .catch((error) => {
        reject(error);
      });
  });
