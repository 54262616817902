import { AuthUser } from 'src/types/types';
import { endpoints } from 'src/config/endpoints';
import apiBase from './api-base';

export const login = (email: string, password: string): Promise<AuthUser> =>
  new Promise((resolve, reject) => {
    apiBase
      .post(endpoints.auth.login, { email, password })
      .then((response) => {
        resolve(response.data.Data as AuthUser);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const register = (
  email: string,
  password: string,
  first_name: string,
  last_name: string,
  frequency: string,
  plan_type: string,
  recaptcha: string
): Promise<AuthUser> =>
  new Promise((resolve, reject) => {
    apiBase
      .post(endpoints.auth.register, {
        email,
        password,
        first_name,
        last_name,
        frequency,
        plan_type,
        recaptcha
      })
      .then((response) => {
        resolve(response.data.Data as AuthUser);
      });
  });

export const resendVerifyEmail = (email: string): Promise<void> =>
  new Promise((resolve, reject) => {
    apiBase.post(endpoints.auth.resendVerifyEmail, { email }).then(() => {
      resolve();
    });
  });

export const getUserByCode = (code: string, is_invitation?: boolean): Promise<AuthUser> =>
  new Promise((resolve, reject) => {
    apiBase
      .get(endpoints.auth.getUserByCode(code), is_invitation ? { params: { is_invitation } } : {})
      .then((response) => {
        resolve(response.data.Data as AuthUser);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const completeInvitation = (
  code: string,
  first_name: string,
  last_name: string,
  password: string
): Promise<AuthUser> =>
  new Promise((resolve, reject) => {
    apiBase
      .post(endpoints.auth.completeInvitation, { code, first_name, last_name, password })
      .then((response) => {
        resolve(response.data.Data as AuthUser);
      });
  });

export const checkConfirmRegister = (email: string): Promise<boolean> =>
  new Promise((resolve, reject) => {
    apiBase.get(endpoints.auth.checkConfirmRegister, { params: { email } }).then((response) => {
      resolve(response.data.Data as boolean);
    });
  });

export const confirmRegister = (code: string): Promise<boolean> =>
  new Promise((resolve, reject) => {
    apiBase.post(endpoints.auth.confirmRegister(code)).then((response) => {
      resolve(response.data.Data as boolean);
    });
  });

export const forgotPassword = (email: string): Promise<boolean> =>
  new Promise((resolve, reject) => {
    apiBase
      .post(endpoints.auth.forgotPassword, { email })
      .then((response) => {
        resolve(response.data.Data as boolean);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const resetPassword = ({
  code,
  newPassword,
}: {
  code: string;
  newPassword: string;
}): Promise<boolean> =>
  new Promise((resolve, reject) => {
    apiBase.post(endpoints.auth.resetPassword, { code, newPassword }).then((response) => {
      resolve(response.data.Data as boolean);
    });
  });
