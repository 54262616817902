import ChartExpenseByYear from 'src/models/chart-expense-by-year';
import ChartExpenseByCategory from 'src/models/chart-expense-by-category';

export const ChartExpensesByYear2019: ChartExpenseByYear[] = [
  {
    month: 1,
    total: 151,
  },
  {
    month: 2,
    total: 3892,
  },
  {
    month: 3,
    total: 12,
  },
  {
    month: 4,
    total: 5645,
  },
  {
    month: 5,
    total: 334,
  },
  {
    month: 6,
    total: 3456,
  },
  {
    month: 7,
    total: 885,
  },
  {
    month: 8,
    total: 2222,
  },
  {
    month: 9,
    total: 4556,
  },
  {
    month: 10,
    total: 9998,
  },
  {
    month: 11,
    total: 233,
  },
  {
    month: 12,
    total: 2500,
  },
];

export const ChartExpensesByYear2020: ChartExpenseByYear[] = [
  {
    month: 1,
    total: 2002,
  },
  {
    month: 2,
    total: 4393,
  },
  {
    month: 3,
    total: 3232,
  },
  {
    month: 4,
    total: 1400,
  },
  {
    month: 5,
    total: 1900,
  },
  {
    month: 6,
    total: 603,
  },
  {
    month: 7,
    total: 347,
  },
  {
    month: 8,
    total: 9789,
  },
  {
    month: 9,
    total: 455,
  },
  {
    month: 10,
    total: 435,
  },
  {
    month: 11,
    total: 4455,
  },
  {
    month: 12,
    total: 2367,
  },
];

export const ChartExpensesByCategory: ChartExpenseByCategory[] = [
  {
    category: 'Food',
    total: 100,
  },
  {
    category: 'Transportation',
    total: 50,
  },
  {
    category: 'Entertainment',
    total: 300,
  },
  {
    category: 'Health',
    total: 350,
  },
  {
    category: 'Education',
    total: 120,
  },
  {
    category: 'Investment',
    total: 15,
  },
  {
    category: 'Others',
    total: 333,
  },
];
