import React, { lazy } from 'react';
import DashboardLayout from 'src/layouts/dashboard';
import { AuthGuard } from 'src/auth/guard';
import { paths } from 'src/routes/paths';

export const UserPlan = lazy(() => import('src/pages/user-plan'));
export const Profile = lazy(() => import('src/pages/profile'));

export const profileRoutes = [
  {
    path: paths.dashboard.profile.plan,
    element: (
      <AuthGuard>
        <DashboardLayout>
          <UserPlan />
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: paths.dashboard.profile.root,
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Profile />
        </DashboardLayout>
      </AuthGuard>
    ),
  },
];
