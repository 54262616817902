import AuthenticationHandler from './authentication';
import CategoriesHandler from './categories';
import ClientsHandler from './clients';
import CostCentersHandler from './cost-centers';
import CurrenciesHandler from './currencies';
import DashboardHandler from './dashboard';
import EnvironmentsHandler from './environments';
import JobOrdersHandler from './job-orders';
import ReportsHandler from './reports';
import TagsHandler from './tags';
import UsersHandler from './users';

export default [
  ...AuthenticationHandler,
  ...CategoriesHandler,
  ...ClientsHandler,
  ...CostCentersHandler,
  ...CurrenciesHandler,
  ...DashboardHandler,
  ...EnvironmentsHandler,
  ...JobOrdersHandler,
  ...ReportsHandler,
  ...TagsHandler,
  ...UsersHandler,
];
